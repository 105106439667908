import { Button, Card, Input, Layout } from "@ui-kitten/components";
import React, { FC, useEffect, useState } from "react";
import { View } from "react-native";

import { Callout } from "@h2c/common/src/molecules/Callout";
import { screenStyles } from "@h2c/common/src/styles";

export type CreateAnnouncementProps = {
  createFunction(title?: string, body?: string): Promise<void>;
  error: string | null;
  originalTitle?: string;
  originalBody?: string;
};

export const AnnouncementCreateVisuals: FC<CreateAnnouncementProps> = ({
  createFunction,
  error,
  originalBody,
  originalTitle,
}) => {
  const [title, setTitle] = useState<string>();
  const [body, setBody] = useState<string>();

  useEffect(() => {
    setTitle(originalTitle);
    setBody(originalBody);
  }, []);

  const handleCreateAnnouncement = async () => {
    createFunction(title, body);
  };

  return (
    <Layout style={screenStyles.webCentredColumn}>
      <View style={screenStyles.screenMainSectionSoButtonsAtBottom}>
        {/* Top Info Section */}
        <Layout style={screenStyles.verticalMargin}>
          <Callout
            icon={{ name: "info-circle" }}
            text="This will send a notification to the phone of all users. Please provide the text in English below. You will then be given the opportunity to
              add text and audio translations."
          />
        </Layout>
        {/* Text input in English */}
        <Card disabled style={screenStyles.verticalMargin}>
          <Input
            onChangeText={(value) => setTitle(value)}
            placeholder="Enter title for announcement in English here"
            value={title}
            label={"Announcement title"}
          />
          <Input
            multiline={true}
            textStyle={{ minHeight: 64, maxHeight: 64 }}
            label={"Announcement information"}
            placeholder="Enter any additional information for announcement in English here"
            onChangeText={(value) => setBody(value)}
            value={body}
          />
        </Card>
      </View>

      {error ? (
        <Callout status={"danger"} icon={{ name: "exclamation-circle" }} text={error} />
      ) : null}
      <Button style={screenStyles.bottomButton} onPress={handleCreateAnnouncement}>
        Next
      </Button>
    </Layout>
  );
};
