import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack/lib/typescript/src/types";
import React, { FC, useContext, useState } from "react";

import { PENANG_COORDINATES } from "@h2c/common/src/constants";
import { fixNull, types } from "@h2c/service";
import { AdminLocation } from "@h2c/service/graphql/custom-types";
import { LocationLevel } from "@h2c/service/graphql/types";
import { Coordinates, LOCATION_TYPE } from "@h2c/service/src/dataItemManipulation";

import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";
import { GeneralActionCreators } from "../../state/general";

import { CreateLocationProps, SettingsAddLocationVisuals } from "./SettingsAddLocationVisuals";

type SettingsAddLocationContainerProps = {
  navigation: StackNavigationProp<RouteParams, "SETTINGS_ADD_LOCATION">;
  route: RouteProp<RouteParams, "ADMIN_SETTINGS">;
};

export const SettingsAddLocationContainer: FC<SettingsAddLocationContainerProps> = ({
  navigation,
}) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [, dispatch] = useContext(GlobalStateContext);
  //Lists both countries and children to allow validation of new city and country names
  const {
    data: locationResults,
    loading: loadingCountries,
  } = types.useListCountriesAndChildrenWithPagesQuery({
    fetchPolicy: "cache-and-network",
  });

  const [location, setLocation] = useState<Coordinates>({
    longitude: PENANG_COORDINATES.longitude,
    latitude: PENANG_COORDINATES.latitude,
  });
  const [createLocationMutation, { loading: creatingLocation }] = types.useCreateLocationMutation();
  const [deleteLocationMutation] = types.useDeleteLocationMutation();

  //Mutations for creating template pages
  const [
    linkPageToLocationMutation,
    { loading: linkingPageToLocation },
  ] = types.useCreatePageLocationMutation();
  const [createPageMutation, { loading: creatingPage }] = types.useCreateDefaultPageMutation();

  //Pages with associated mutation

  const updateCentreCoordinatesOfNewLocation = (newLocation?: Coordinates) => {
    if (newLocation) setLocation(newLocation);
    navigation.pop();
  };

  const navigateToChooseCentreCoordinatesOfNewLocation = () => {
    navigation.navigate("LOCATION_CHOOSE", {
      onSubmit: updateCentreCoordinatesOfNewLocation,
      initialPosition: location,
      icon: "map-marker-alt",
      custom: false,
    });
  };

  //Uses provided location name to check if country or city with same name, regardless of capitalization, already exists
  const checkIfCountryExists = (countryName: string): string | null => {
    let error = null;
    const countryNames = fixNull(locationResults?.byLevel?.items).map((l) => l.name.toLowerCase());

    if (countryNames.includes(countryName.toLowerCase())) {
      error = `A country called ${countryName} already exists`;
    }

    return error;
  };

  const checkCityExistsWithinCountry = (parentCountry: string, cityName: string): string | null => {
    let error = null;
    const cityNames = fixNull(locationResults?.byLevel?.items)
      .filter((country) => country.name === parentCountry)
      .map((l) => l?.children?.items?.map((c) => c?.name.toLowerCase()))
      .flat();

    if (cityNames.includes(cityName.toLowerCase())) {
      error = `A city called ${cityName} already exists in ${parentCountry}`;
    }
    return error;
  };

  //Following confirmation page, updates location in global state to new location then navigates to edit pages
  const onCreationOfNewLocation = (
    locationName: string,
    createdLocationId: string,
    locationType: LocationLevel,
    parentCountryId: string,
    parentCountryName: string
  ) => {
    let newLocationInformationForState: AdminLocation | null = null;
    let parentLocationInformationForState: AdminLocation | null = null;

    //Creates location object used to update currently selected location in global state
    if (location.latitude && location.longitude) {
      newLocationInformationForState = {
        id: createdLocationId,
        name: locationName,
        centreCoordinates: { latitude: location.latitude, longitude: location.longitude },
        level: locationType,
      };
    }

    //Creates parent location object used to update currently selected country in global state if city selected
    if (locationType === LocationLevel.City) {
      const parentCoords = locationResults?.byLevel?.items?.find(
        (country) => country?.id === parentCountryId
      )?.centreCoordinates;
      parentLocationInformationForState = {
        id: parentCountryId,
        name: parentCountryName,
        centreCoordinates: parentCoords,
        level: LocationLevel.Country,
      };
    }

    if (newLocationInformationForState) {
      dispatch(GeneralActionCreators.locationUpdate(newLocationInformationForState));
      dispatch(
        GeneralActionCreators.countryUpdate(
          parentLocationInformationForState || newLocationInformationForState
        )
      );
    }

    //Navigates to information page after confirmation
    navigation.navigate("INFORMATION_PAGES");
  };

  const handleCreateLocationError = (locationId: string) => {
    return deleteLocationMutation({ variables: { id: locationId } });
  };

  //Creates 6 new template pages when new country is added and links them to location
  const createTemplatePageAndLinkToLocation = async (locationID: string) => {
    // Create page
    let pageID: string | undefined = undefined;
    try {
      const page = await createPageMutation();
      pageID = page.data?.createPage?.id;
    } catch (e) {
      return setValidationErrors([
        `Could not create default page for location, everything should still work fine but contact support if you have worries — ${e.message}`,
      ]);
    }
    // Ensure page has been created
    if (!pageID) return setValidationErrors(["Unknown error whilst creating page"]);

    // Link page to location
    try {
      await linkPageToLocationMutation({ variables: { pageID, locationID } });
    } catch (e) {
      return setValidationErrors([
        `Could not create default page everything should still work fine but contact support if you have worries — Link to location: ${e.message}`,
      ]);
    }
    setValidationErrors([]);
  };

  const onCreateNewLocation = async ({
    locationName,
    locationCentreCoords,
    locationType,
    parentCountryId,
    parentCountryName,
  }: CreateLocationProps) => {
    const errors = [];

    //Check if country or city already exists
    const locationExistsError =
      locationType === LocationLevel.Country
        ? checkIfCountryExists(locationName)
        : checkCityExistsWithinCountry(parentCountryName, locationName);
    if (locationExistsError) errors.push(locationExistsError);

    if (errors.length == 0) {
      //Check to satisfy typescript, location is already mandatory on client side
      if (locationCentreCoords.latitude && locationCentreCoords.longitude) {
        //Attempt to create new location
        let locationId: string | undefined = undefined;
        try {
          const createdLocationData = await createLocationMutation({
            variables: {
              level: locationType,
              locationLocation: {
                latitude: locationCentreCoords.latitude,
                longitude: locationCentreCoords.longitude,
              },
              parentLocation: locationType === LocationLevel.City ? parentCountryId : "World",
              locationName: locationName,
            },
          });

          locationId = createdLocationData?.data?.createAdministrationLocation?.id;
          if (!locationId)
            errors.push(
              `There was a problem creating your new location — no id returned from server. Please try again`
            );
          else {
            await createTemplatePageAndLinkToLocation(locationId);
          }
        } catch (e) {
          errors.push(`There was a problem creating your new location — ${e.message}`);
          if (locationId) {
            // When there is an error, delete the country if created in server and inform user
            handleCreateLocationError(locationId);
          }
        }

        if (errors.length == 0 && locationId) {
          navigation.navigate("CONFIRMATION", {
            confirmationText: `The ${
              locationType === LocationLevel.City ? "City" : "Country"
            } called ${locationName} ${
              locationType === LocationLevel.City ? `located in ${parentCountryName}` : ""
            } with coordinates: ${locationCentreCoords.latitude}  ${
              locationCentreCoords.longitude
            } has been successfully created`,
            onContinue: () =>
              onCreationOfNewLocation(
                locationName,
                // @ts-ignore Explicit check already takes place above - typescript doesn't recognise
                locationId,
                locationType,
                parentCountryId,
                parentCountryName
              ),
          });
        }
      } else {
        errors.push("Need to provide a valid location");
      }
    }
    setValidationErrors(errors);
  };

  return (
    <SettingsAddLocationVisuals
      location={location}
      navigateToChooseLocation={navigateToChooseCentreCoordinatesOfNewLocation}
      types={LOCATION_TYPE}
      loadingLocations={loadingCountries}
      onCreate={onCreateNewLocation}
      locationsToDisplay={fixNull(locationResults?.byLevel?.items)}
      error={validationErrors.join(", ")}
      creatingLocation={creatingLocation || creatingPage || linkingPageToLocation}
    />
  );
};
