import {
  Button,
  Card,
  Icon,
  IndexPath,
  Input,
  Layout,
  ListItem,
  Select,
  SelectItem,
  Spinner,
} from "@ui-kitten/components";
import React, { FC, useEffect, useState } from "react";

import { screenStyles } from "@h2c/common/src";
import { SOURCE_TEXT } from "@h2c/common/src/i18n/static-text";
import { Callout } from "@h2c/common/src/molecules/Callout";
import { UpdatePageBlock } from "@h2c/common/src/molecules/UpdatePageBlock";
import { ViewLocation } from "@h2c/common/src/molecules/ViewLocation";
import { InformationModal } from "@h2c/common/src/organisms";
import { customTypes } from "@h2c/service";
import { LocationLevel } from "@h2c/service/graphql/types";
import { GenericDataItemDetailsType, Coordinates } from "@h2c/service/src/dataItemManipulation";

export type CreateLocationProps = {
  locationType: LocationLevel;
  parentCountryId: string;
  parentCountryName: string;
  locationName: string;
  locationCentreCoords: Coordinates;
};

type SettingsAddLocationVisualProps = {
  navigateToChooseLocation: () => void;
  location: Coordinates;
  types: GenericDataItemDetailsType<LocationLevel>[];
  loadingLocations: boolean;
  locationsToDisplay: customTypes.AdminLocation[];
  onCreate: (props: CreateLocationProps) => void;
  creatingLocation: boolean;
  error?: string;
};

export const SettingsAddLocationVisuals: FC<SettingsAddLocationVisualProps> = ({
  navigateToChooseLocation,
  location,
  types,
  locationsToDisplay,
  onCreate,
  creatingLocation,
  error,
}) => {
  const [locationType, setLocationType] = useState<LocationLevel>(LocationLevel.Country);
  const [selectedCountryIndex, setSelectedCountryIndex] = useState<IndexPath | undefined>();
  const [selectedCountryName, setSelectedCountryName] = useState<string | undefined>();
  const [selectedCountryId, setSelectedCountryId] = useState<string | undefined>();
  const [locationName, setLocationName] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loadingLocationsState, setLoadingLocationsState] = useState(true);

  useEffect(() => {
    setLoadingLocationsState(true);
  }, [loadingLocationsState]);

  const warningText = {
    city: `You are about to create a new city called ${locationName} located in ${selectedCountryName} with coordinates: ${location.latitude}, ${location.longitude}.\n\nOnce created, this city will be visible to all users of the admin site and HelpToConnect mobile app.\n\nYou will not be able to delete this location once it has been created.\n\nAre you sure you want to create this city?`,
    country: `You are about to create a new country called ${locationName} with coordinates: ${location.latitude}, ${location.longitude}.\n\nOnce created, this country will be visible to all users of the admin site and HelpToConnect mobile app.\n\nYou will not be able to delete this location once it has been created.\n\nAre you sure you want to create this country?`,
  };

  const onSelectCountry = (index: IndexPath, name: string, id: string) => {
    setSelectedCountryIndex(index);
    setSelectedCountryName(name);
    setSelectedCountryId(id);
  };

  const onSubmit = () => {
    onCreate({
      locationName: locationName,
      locationCentreCoords: location,
      locationType: locationType,
      parentCountryId: selectedCountryId || "World",
      parentCountryName: selectedCountryName || "World",
    });
    setShowConfirmation(false);
  };

  const getLocationType = (): string => {
    const locationStaticTextKey = types.find((l) => l.type === locationType)?.text;
    return locationStaticTextKey ? SOURCE_TEXT[locationStaticTextKey] : "Location";
  };

  return (
    <Layout style={screenStyles.webCentredColumnNarrow}>
      <Card disabled style={screenStyles.screenMainSectionSoButtonsAtBottom}>
        <Callout
          icon={{ name: "info-circle" }}
          text="Once you have added a new location, you will not be able to remove it"
        />
        <UpdatePageBlock title={`Type of location *`}>
          {types.map((l) => (
            <ListItem
              key={l.type}
              title={SOURCE_TEXT[l.text]}
              onPress={() => setLocationType(l.type)}
              style={{ backgroundColor: "#FFFF" }}
              accessoryRight={
                locationType === l.type ? (props) => <Icon name="check" {...props} /> : undefined
              }
            />
          ))}
        </UpdatePageBlock>

        {locationType === LocationLevel.City ? (
          <UpdatePageBlock title={"Which country is this city in? *"}>
            {loadingLocationsState ? (
              <Select
                selectedIndex={selectedCountryIndex}
                // @ts-ignore - UI Kitten types expect index or an array even for single select
                onSelect={(index: IndexPath) =>
                  onSelectCountry(
                    index,
                    locationsToDisplay[index.row].name,
                    locationsToDisplay[index.row].id
                  )
                }
                value={selectedCountryName}
                placeholder={"Select a parent country for your city"}
              >
                {locationsToDisplay.map((l) => (
                  <SelectItem key={l.id} title={l.name} />
                ))}
              </Select>
            ) : (
              <Spinner />
            )}
          </UpdatePageBlock>
        ) : null}

        <UpdatePageBlock title={`Name of ${getLocationType()} *`}>
          <Input value={locationName} onChangeText={setLocationName} />
        </UpdatePageBlock>

        <UpdatePageBlock
          editButton={true}
          onClickEditButton={navigateToChooseLocation}
          title={"Centre coordinates *"}
        >
          <ViewLocation
            location={location}
            icon={"map-marker-alt"}
            onPress={navigateToChooseLocation}
            confirmed={false}
            custom={false}
          />
        </UpdatePageBlock>
      </Card>
      {/*TODO(JACK): Better handle errors*/}
      {error ? (
        <Callout status={"danger"} icon={{ name: "exclamation-circle" }} text={error} />
      ) : null}
      <Button
        // Validation based on shortest country and city names
        disabled={
          locationType === LocationLevel.City
            ? locationName.length < 2 || !selectedCountryName
            : locationName.length < 4
        }
        style={screenStyles.buttonAtBottomOfForm}
        onPress={() => setShowConfirmation(true)}
      >
        Create new location
      </Button>
      {showConfirmation ? (
        <InformationModal
          visibility={showConfirmation}
          displayText={locationType === LocationLevel.City ? warningText.city : warningText.country}
          onCancel={() => setShowConfirmation(false)}
          onSubmit={onSubmit}
          submitText={"Create new location"}
          loading={creatingLocation}
          typeToConfirmText={locationName}
          typeToConfirmHeader={`Please type the name of your new ${locationType} before proceeding`}
          submitStatus={"success"}
        />
      ) : null}
    </Layout>
  );
};
