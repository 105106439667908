import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";

import {
  AboutScreen,
  ConfirmationScreen,
  ChooseLocationScreen,
  HelpSitesUpdateScreen,
  HelpSitesCreateScreen,
  LanguageEditScreen,
  LanguageEditTutorialVideoAudioScreen,
  LanguagesHomeScreen,
  LanguageAddNewTextLangScreen,
  LanguageAddNewAudioLangScreen,
  PageEditScreen,
  PageEditPreambleScreen,
  SettingsAddLocationScreen,
  SettingsCreateAdminScreen,
  SettingsUpdateAdminScreen,
  TutorialVideoScreen,
  AnnouncementCreateScreen,
  AnnouncementSummaryScreen,
  AnnouncementChooseTranslationScreen,
  AnnouncementAddTextTranslationScreen,
  AnnouncementAddAudioTranslationScreen,
  AnnouncementPostLocationSelectScreen,
  CovidReportUpdateScreen,
  SupplyRequestUpdateScreen,
  IncidentsUpdateScreen,
} from "../screens";
import { PageBlockEditScreen } from "../screens/PageBlockEditScreen";
import { PageManageAudioTranslationsScreen } from "../screens/PageManageAudioTranslationsScreen";
import { PageManageTextTranslationsScreen } from "../screens/PageManageTextTranslationsScreen";
import { PageSaveScreen } from "../screens/PageSaveScreen";
import { PageTranslateScreen } from "../screens/PageTranslateScreen";
import { PageViewScreen } from "../screens/PageViewScreen";
import { SettingsUpdateEmergencyContactsContainer } from "../screens/SettingsUpdateEmergencyContacts/SettingsUpdateEmergencyContactsContainer";

import { DrawerMenu } from "./DrawerMenu";
import { RouteParams } from "./types";

const { Navigator, Screen } = createStackNavigator<RouteParams>();

export const MainStack = () => {
  return (
    <Navigator>
      <Screen name="MAIN_DRAWER_ITEMS" component={DrawerMenu} options={{ headerShown: false }} />
      {/*Help to connect*/}
      <Screen
        name="CONFIRMATION"
        component={ConfirmationScreen}
        options={{ headerTitle: "Confirm" }}
      />
      <Screen name="PAGE_EDIT" component={PageEditScreen} options={{ headerShown: false }} />
      <Screen
        name="PAGE_EDIT_PREAMBLE"
        component={PageEditPreambleScreen}
        options={{ headerShown: false }}
      />
      <Screen
        name="PAGE_BLOCK_EDIT"
        component={PageBlockEditScreen}
        options={{ headerShown: false }}
      />
      <Screen
        name="PAGE_TRANSLATE"
        component={PageTranslateScreen}
        options={{ headerShown: false }}
      />
      <Screen
        name="PAGE_TRANSLATE_TEXT_MANAGE"
        options={{ headerShown: false }}
        component={PageManageTextTranslationsScreen}
      />
      <Screen
        name="PAGE_TRANSLATE_AUDIO_MANAGE"
        options={{ headerShown: false }}
        component={PageManageAudioTranslationsScreen}
      />

      {/*
      Headers on pages have a slightly different design to rest of site. Page edit requires
      custom undo / redo in the top right and the rest use the same style for consistency
      */}
      <Screen name="PAGE_SAVE" component={PageSaveScreen} options={{ headerShown: false }} />
      <Screen name="PAGE_VIEW" component={PageViewScreen} options={{ headerShown: false }} />
      <Screen
        name="LOCATION_CHOOSE"
        component={ChooseLocationScreen}
        options={{ headerTitle: "Edit location" }}
      />
      <Screen
        name="HELP_SITE_CREATE"
        component={HelpSitesCreateScreen}
        options={{ headerTitle: "Create Help Site" }}
      />
      <Screen name="LANGUAGES_HOME" component={LanguagesHomeScreen} />
      <Screen
        name="LANGUAGE_EDIT"
        component={LanguageEditScreen}
        options={{ headerTitle: "Edit Language" }}
      />
      <Screen
        name="LANGUAGE_TUTORIAL_VOICE_OVER"
        component={LanguageEditTutorialVideoAudioScreen}
        options={{ headerTitle: "Edit Tutorial Voice Overs" }}
      />
      <Screen
        name="LANGUAGE_CREATE_TEXT"
        component={LanguageAddNewTextLangScreen}
        options={{ headerTitle: "Add New Text Language" }}
      />
      <Screen
        name="LANGUAGE_CREATE_AUDIO"
        component={LanguageAddNewAudioLangScreen}
        options={{ headerTitle: "Add New Audio Language" }}
      />
      <Screen
        name="SETTINGS_ADD_LOCATION"
        component={SettingsAddLocationScreen}
        options={{ headerTitle: "Add location" }}
      />
      <Screen
        name="SETTINGS_ADD_ADMIN"
        component={SettingsCreateAdminScreen}
        options={{ headerTitle: "Add Admin" }}
      />
      <Screen
        name="SETTINGS_UPDATE_ADMIN"
        component={SettingsUpdateAdminScreen}
        options={{ headerTitle: "Update Admin" }}
      />
      <Screen
        name="SETTINGS_UPDATE_EMERGENCY_CONTACTS"
        component={SettingsUpdateEmergencyContactsContainer}
        options={{ headerTitle: "Update Emergency Contacts" }}
      />
      <Screen
        name="TUTORIAL_VIDEO"
        component={TutorialVideoScreen}
        options={{ headerTitle: "Tutorial Video" }}
      />
      <Screen name="ABOUT" component={AboutScreen} />
      <Screen
        name="HELP_SITE_UPDATE"
        component={HelpSitesUpdateScreen}
        options={{ headerTitle: "Update Help Site" }}
      />
      <Screen
        name="INCIDENT_UPDATE"
        component={IncidentsUpdateScreen}
        options={{ headerTitle: "Update Incident Report" }}
      />
      {/*COVID 19 Routes - No longer required, kept in for typing*/}
      <Screen
        name="CREATE_ANNOUNCEMENT"
        component={AnnouncementCreateScreen}
        options={{
          headerTitle: "Create Announcement",
        }}
      />
      <Screen
        name="UPLOAD_ANNOUNCEMENT"
        component={AnnouncementSummaryScreen}
        options={{
          headerTitle: "Upload Announcement",
        }}
      />
      <Screen
        name="CHOOSE_TRANSLATION"
        component={AnnouncementChooseTranslationScreen}
        options={{ title: "Choose translation" }}
      />
      <Screen
        name="ANNOUNCEMENT_ADD_TEXT_TRANSLATION"
        component={AnnouncementAddTextTranslationScreen}
        options={{ headerShown: false }}
      />
      <Screen
        name="ANNOUNCEMENT_ADD_AUDIO_TRANSLATION"
        component={AnnouncementAddAudioTranslationScreen}
        options={{ headerTitle: "Add Audio Translation" }}
      />
      <Screen
        name="ANNOUNCEMENT_SELECT_LOCATION"
        component={AnnouncementPostLocationSelectScreen}
        options={{ headerTitle: "Select Location" }}
      />
      <Screen
        name="COVID_REPORT_UPDATE"
        component={CovidReportUpdateScreen}
        options={{ headerTitle: "Update COVID Report" }}
      />
      <Screen
        name="SUPPLY_REQUEST_UPDATE"
        component={SupplyRequestUpdateScreen}
        options={{ headerTitle: "Update Supply Request" }}
      />
    </Navigator>
  );
};
