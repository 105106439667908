import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { RouteProp } from "@react-navigation/native";
import * as React from "react";
import { FC } from "react";

import { TopBarDrawerScreens } from "../molecules/TopBarDrawerScreens";
import { CovidReportListScreen, CovidReportMapScreen } from "../screens";
import { IncidentsListScreen } from "../screens/Incidents/IncidentsListScreen";
import { IncidentsMapContainer } from "../screens/Incidents/IncidentsMapContainer";

import { ReportAndRequestTabBar } from "./ReportAndRequestTabBar";
import { RouteParams } from "./types";

const { Navigator, Screen } = createMaterialTopTabNavigator<RouteParams>();

type CovidReportTabsProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
  route: RouteProp<RouteParams, "INCIDENTS">;
};

export const IncidentsTabs: FC<CovidReportTabsProps> = ({ navigation }) => {
  return (
    <>
      <TopBarDrawerScreens navigation={navigation} />
      <Navigator
        tabBar={(props: any) => <ReportAndRequestTabBar {...props} />}
        swipeEnabled={false}
      >
        <Screen name="INCIDENTS_LIST" component={IncidentsListScreen} options={{ title: "List" }} />
        <Screen name="INCIDENTS_MAP" component={IncidentsMapContainer} options={{ title: "Map" }} />
      </Navigator>
    </>
  );
};
