import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { RouteProp } from "@react-navigation/native";
import * as React from "react";
import { FC } from "react";

import { TopBarDrawerScreens } from "../molecules/TopBarDrawerScreens";
import { SupplyRequestListScreen, SupplyRequestMapScreen } from "../screens";

import { ReportAndRequestTabBar } from "./ReportAndRequestTabBar";
import { RouteParams } from "./types";

const { Navigator, Screen } = createMaterialTopTabNavigator<RouteParams>();

type SupplyRequestTabsProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
  route: RouteProp<RouteParams, "REQUESTS">;
};
export const SupplyRequestTabs: FC<SupplyRequestTabsProps> = ({ navigation }) => {
  return (
    <>
      <TopBarDrawerScreens navigation={navigation} />
      <Navigator
        tabBar={(props: any) => <ReportAndRequestTabBar {...props} />}
        swipeEnabled={false}
      >
        <Screen
          name="SUPPLY_REQUEST_LIST"
          component={SupplyRequestListScreen}
          options={{ title: "List" }}
        />
        <Screen
          name="SUPPLY_REQUEST_MAP"
          component={SupplyRequestMapScreen}
          options={{ title: "Map" }}
        />
        {/*<Screen*/}
        {/*  name="SUPPLY_REQUEST_STATISTICS"*/}
        {/*  component={SupplyRequestStatisticsScreen}*/}
        {/*  options={{ title: "Statistics" }}*/}
        {/*/>*/}
      </Navigator>
    </>
  );
};
