import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { RouteProp } from "@react-navigation/native";
import * as React from "react";
import { FC } from "react";

import { TopBarDrawerScreens } from "../molecules/TopBarDrawerScreens";
import { CovidReportListScreen, CovidReportMapScreen } from "../screens";

import { ReportAndRequestTabBar } from "./ReportAndRequestTabBar";
import { RouteParams } from "./types";

const { Navigator, Screen } = createMaterialTopTabNavigator<RouteParams>();

type CovidReportTabsProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
  route: RouteProp<RouteParams, "REPORTS">;
};

export const CovidReportTabs: FC<CovidReportTabsProps> = ({ navigation }) => {
  return (
    <>
      <TopBarDrawerScreens navigation={navigation} />
      <Navigator
        tabBar={(props: any) => <ReportAndRequestTabBar {...props} />}
        swipeEnabled={false}
      >
        <Screen
          name="COVID_REPORT_LIST"
          component={CovidReportListScreen}
          options={{ title: "List" }}
        />
        <Screen
          name="COVID_REPORT_MAP"
          component={CovidReportMapScreen}
          options={{ title: "Map" }}
        />
        {/*<Screen*/}
        {/*  name="COVID_REPORT_STATISTICS"*/}
        {/*  component={CovidReportStatisticsScreen}*/}
        {/*  options={{ title: "Statistics" }}*/}
        {/*/>*/}
      </Navigator>
    </>
  );
};
