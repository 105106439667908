import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import {
  Button,
  Text,
  Spinner,
  Input,
  Icon,
  IconProps,
  List,
  ListItem,
  Card,
  Layout,
  useTheme,
  Divider,
} from "@ui-kitten/components";
import React, { Dispatch, FC, SetStateAction } from "react";
import { ScrollView, View } from "react-native";

import { screenStyles } from "@h2c/common/src";
import { VisibilityIcon } from "@h2c/common/src/atoms";
import { Callout } from "@h2c/common/src/molecules/Callout";

import { RouteParams } from "../../routes";
import { UserType } from "../../types/cognitoidentityserviceprovider";

type AdminEmailListItem = {
  user: UserType;
};

type SettingsVisualsProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
  handleSignOut: () => void;
  handleResetPassword: () => void;
  loadingSignOut: boolean;
  passwordChangeErrors: string[];
  loadingChangePassword: boolean;
  secureTextEntry: boolean;
  setSecureTextEntry: Dispatch<SetStateAction<boolean>>;
  newPassword: string;
  setNewPassword: Dispatch<SetStateAction<string>>;
  currentPassword: string;
  setCurrentPassword: Dispatch<SetStateAction<string>>;
  LocationSelectWithModal: FC;
  onAddLocation: () => void;
  onAddAdmin: (superAdmin: boolean) => void;
  onUpdateAdmin: (admin: UserType) => void;
  admins: UserType[];
  loadingAdmins: boolean;
  superAdmins: UserType[];
  loadingSuperAdmins: boolean;
  currentUserIsSuperAdmin: boolean;
  navigateToTutorialVideo: () => void;
  onAbout?: () => void;
  onEditEmergencyContactsForLocation: () => void;
};

export const SettingsHomeVisuals: FC<SettingsVisualsProps> = ({
  handleSignOut,
  handleResetPassword,
  secureTextEntry,
  passwordChangeErrors,
  loadingChangePassword,
  loadingSignOut,
  setSecureTextEntry,
  newPassword,
  setNewPassword,
  currentPassword,
  setCurrentPassword,
  LocationSelectWithModal,
  onAddLocation,
  admins,
  loadingAdmins,
  superAdmins,
  loadingSuperAdmins,
  onAddAdmin,
  onUpdateAdmin,
  currentUserIsSuperAdmin,
  navigateToTutorialVideo,
  onAbout,
  onEditEmergencyContactsForLocation,
}) => {
  const theme = useTheme();

  const renderPasswordVisibilityIcon = (props: IconProps) => (
    <VisibilityIcon
      secureTextEntry={secureTextEntry}
      setSecureTextEntry={setSecureTextEntry}
      props={props}
    />
  );

  //Generic list item to display admins email, option to edit and status of account
  const AdminEmailListItem = ({ user }: AdminEmailListItem) => {
    // Email of user is stored at last position in Attributes array
    const email = user?.Attributes?.[user.Attributes.length - 1].Value;
    return (
      <ListItem
        style={{ backgroundColor: "#FFF" }}
        key={user.Username}
        accessoryRight={(props) => <Icon {...props} name={"edit"} />}
        accessoryLeft={(props) =>
          user.Enabled ? (
            <Icon {...props} name={"user"} />
          ) : (
            <Icon color={theme["color-warning-500"]} {...props} name={"user-slash"} />
          )
        }
        onPress={() => onUpdateAdmin(user)}
        title={email}
      />
    );
  };

  // Ability to edit locations or other admin
  const renderSuperAdminSettings = () => {
    if (currentUserIsSuperAdmin) {
      return (
        <>
          {/*Locations section*/}
          <Divider />
          <View style={screenStyles.verticalMargin}>
            <Text category={"h2"}>Locations</Text>
            <View>
              <Text category={"label"}>Currently selected location</Text>
              <LocationSelectWithModal />
              <Button
                accessoryLeft={(props) => <Icon {...props} name={"mobile"} />}
                onPress={onEditEmergencyContactsForLocation}
                style={{ marginVertical: 10 }}
              >
                Update emergency contacts
              </Button>
              <Text style={{ marginVertical: 8, fontStyle: "italic" }}>
                Emergency contacts are the phone numbers that will appear to users (in the above
                location) when they click the emergency button.
              </Text>
              <Button status={"success"} onPress={onAddLocation} style={{ marginVertical: 10 }}>
                Add New Location
              </Button>
            </View>
          </View>
          {/*Admin Management*/}
          <Divider />
          <View style={screenStyles.verticalMargin}>
            <Text category={"h2"}>Manage Admins</Text>
            <View style={screenStyles.verticalMargin}>
              <Text category={"h4"}>Admins</Text>
              {loadingAdmins ? (
                <Spinner />
              ) : (
                <List
                  style={{ margin: 15 }}
                  data={admins}
                  renderItem={({ item }) => <AdminEmailListItem user={item} />}
                />
              )}
              <Button status={"success"} onPress={() => onAddAdmin(false)}>
                Add Admin
              </Button>
            </View>
            <View style={screenStyles.verticalMargin}>
              <Text category={"h4"}>Super Admins</Text>
              {loadingSuperAdmins ? (
                <Spinner />
              ) : (
                <List
                  style={{ margin: 15 }}
                  data={superAdmins}
                  renderItem={({ item }) => <AdminEmailListItem user={item} />}
                />
              )}
              <Button status={"success"} onPress={() => onAddAdmin(true)}>
                Add Super Admin
              </Button>
            </View>
          </View>
        </>
      );
    }
  };

  return (
    <ScrollView>
      <Layout style={screenStyles.paddedListSection16}>
        <Card disabled style={screenStyles.webCentredColumn}>
          <Button
            style={screenStyles.spacedButton}
            onPress={navigateToTutorialVideo}
            appearance={"outline"}
            accessoryLeft={(props) => <Icon name="video" pack="solid" {...props} />}
            accessoryRight={(props) => <Icon name="arrow-right" pack="solid" {...props} />}
          >
            Tutorial Video
          </Button>
          <Divider />
          {/*Account section*/}
          <View style={screenStyles.verticalMargin}>
            <Text category={"h2"}>Account</Text>
            <View style={screenStyles.verticalMargin}>
              {/*Sign out button*/}
              <Button
                style={screenStyles.bottomButton}
                status={"info"}
                onPress={handleSignOut}
                appearance={loadingSignOut ? "outline" : "filled"}
                accessoryRight={loadingSignOut ? () => <Spinner status={"basic"} /> : undefined}
              >
                Sign Out
              </Button>
              {/*Reset Password*/}
              <Text category={"h4"}>Reset Password</Text>
              <Input
                label={"Current Password"}
                value={currentPassword}
                onChangeText={setCurrentPassword}
                secureTextEntry={secureTextEntry}
                accessoryRight={renderPasswordVisibilityIcon}
                caption="Should contain at least 6 characters"
                captionIcon={(props: IconProps) => <Icon {...props} name="exclamation-triangle" />}
              />
              <Input
                label={"New Password"}
                value={newPassword}
                onChangeText={setNewPassword}
                secureTextEntry={secureTextEntry}
                accessoryRight={renderPasswordVisibilityIcon}
                caption="Should contain at least 6 characters"
                captionIcon={(props: IconProps) => <Icon {...props} name="exclamation-triangle" />}
              />
              {passwordChangeErrors.length > 0 ? (
                <Callout
                  status={"danger"}
                  icon={{ name: "exclamation-circle" }}
                  text={
                    passwordChangeErrors.join(", ") ||
                    "There was a problem resetting your password, please try agin"
                  }
                />
              ) : null}
              <Button
                appearance={loadingChangePassword ? "outline" : "filled"}
                onPress={() => handleResetPassword()}
                disabled={currentPassword.length < 6 || newPassword.length < 6}
                accessoryRight={
                  loadingChangePassword ? () => <Spinner status="primary" /> : undefined
                }
              >
                Reset Password
              </Button>
            </View>
          </View>
          {currentUserIsSuperAdmin && renderSuperAdminSettings()}
          {onAbout && (
            <>
              <Divider />
              <Button style={{ marginTop: 16 }} onPress={onAbout}>
                About This App
              </Button>
            </>
          )}
        </Card>
      </Layout>
    </ScrollView>
  );
};
