import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import { RouteProp, useIsFocused } from "@react-navigation/native";
import { Text } from "@ui-kitten/components";
import React, { FC, useContext, useEffect, useState } from "react";

import { useLangs } from "@h2c/common/src/hooks/useLangs";
import { BUNDLED_TEXT_LANGS, LangsWithDetails, LanguageContext } from "@h2c/common/src/i18n";
import { DataList } from "@h2c/common/src/organisms";
import {
  ChooseTranslationVisuals,
  TranslatableLanguage,
} from "@h2c/common/src/organisms/ChooseTranslationVisuals";

import { TopBarDrawerScreens } from "../../molecules/TopBarDrawerScreens";
import { RouteParams } from "../../routes";

type AppContentHomeContainerProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
  route: RouteProp<RouteParams, "LANGUAGES_HOME">;
};

/**
 * Container for update screen of Help sites using HelpSitesEditVisuals
 */
export const LanguagesHomeScreen: FC<AppContentHomeContainerProps> = ({ navigation }) => {
  const {
    setAvailableStaticTextAndTextLangs,
    availableTextLangsWithDetails,
    availableAudioLangsWithDetails,
    setAvailableAudioLangs,
  } = useContext(LanguageContext);

  // Update langs every time screen becomes focused
  const { langsUpdate, langsLoading } = useLangs(
    setAvailableStaticTextAndTextLangs,
    setAvailableAudioLangs
  );
  // Updates the static langs used to render the edit pages
  const updateStaticLangs = () => {
    langsUpdate();
  };

  // Text langs stored locally with status
  const [translatableTextLanguages, setTranslatableTextLanguages] = useState<
    null | TranslatableLanguage[]
  >(null);
  // Audio langs stored locally with status
  const [translatableAudioLanguages, setTranslatableAudioLanguages] = useState<
    null | TranslatableLanguage[]
  >(null);

  // Update local text langs with status, filtering out english
  useEffect(() => {
    setTranslatableTextLanguages(
      getTranslationStatus(availableTextLangsWithDetails).filter(
        (t) => t.id !== BUNDLED_TEXT_LANGS.EN
      )
    );
  }, [availableTextLangsWithDetails]);

  // Update local audio langs with status
  useEffect(() => {
    setTranslatableAudioLanguages(getTranslationStatus(availableAudioLangsWithDetails));
  }, [availableAudioLangsWithDetails]);

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      updateStaticLangs();
    }
  }, [isFocused]);

  const renderTextLangSection = () => (
    <ChooseTranslationVisuals
      onSelect={(language: TranslatableLanguage) =>
        navigation.navigate("LANGUAGE_EDIT", {
          language,
        })
      }
      translatableLanguages={translatableTextLanguages || []}
      addNew={() => navigation.navigate("LANGUAGE_CREATE_TEXT")}
      addNewLangText={"Add New Text Language"}
      showLegend={false}
      ListHeaderComponent={() => (
        <Text style={{ textAlign: "center" }} category={"h5"}>
          Text Languages
        </Text>
      )}
    />
  );

  const renderAudioLangSection = () => (
    <ChooseTranslationVisuals
      onSelect={(language: TranslatableLanguage) =>
        navigation.navigate("LANGUAGE_EDIT", {
          language,
          updateAudio: true,
        })
      }
      translatableLanguages={translatableAudioLanguages || []}
      addNew={() => navigation.navigate("LANGUAGE_CREATE_AUDIO")}
      addNewLangText={"Add New Audio Language"}
      showLegend={false}
      ListHeaderComponent={() => (
        <Text style={{ textAlign: "center" }} category={"h5"}>
          Audio Languages
        </Text>
      )}
    />
  );

  // Text + Audio Sections in array so they can both be put in one list
  const langTypesSections = [renderTextLangSection, renderAudioLangSection];

  return (
    <>
      <TopBarDrawerScreens navigation={navigation} />
      <DataList
        title={"Edit Languages"}
        noDataMessage={"Can't Find Languages — contact support"}
        onRefresh={updateStaticLangs}
        refreshing={langsLoading}
        data={langTypesSections}
        renderItem={({ item }) => item()}
      />
    </>
  );
};

const getTranslationStatus = (langWithDetails: LangsWithDetails[]): TranslatableLanguage[] => {
  //  Returns an object with language details and translated status determined by announcement in state
  return langWithDetails.map((detailedLang) => {
    return { ...detailedLang, state: { type: "missing" } };
  });
};
