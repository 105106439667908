import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Button, Card, Layout, List, Spinner, Divider, Text } from "@ui-kitten/components";
import React, { FC, useContext, useEffect, useState } from "react";
import { ListRenderItem } from "react-native";
import "react-phone-number-input/style.css";

import { screenStyles } from "@h2c/common/src";
import { Callout, UpdatePageBlock } from "@h2c/common/src/molecules";
import { customTypes, types } from "@h2c/service";
import { EmergencyContact } from "@h2c/service/graphql/types";

import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";
import { GeneralActionCreators } from "../../state/general";

import { UpdateAdminEmergencyContact } from "./UpdateAdminEmergencyContact";

type SettingsUpdateAdminContainerProps = {
  navigation: StackNavigationProp<RouteParams, "SETTINGS_UPDATE_EMERGENCY_CONTACTS">;
  route: RouteProp<RouteParams, "SETTINGS_UPDATE_EMERGENCY_CONTACTS">;
};

// Screen to add, edit and delete emergency numbers for a location
export const SettingsUpdateEmergencyContactsContainer: FC<SettingsUpdateAdminContainerProps> = ({
  navigation,
}) => {
  const [{ general }, dispatch] = useContext(GlobalStateContext);
  const [emergencyContacts, setEmergencyContacts] = useState<EmergencyContact[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [editingNumber, setEditingNumber] = useState(false);
  const [
    updateLocationEmergencyContactsMutation,
  ] = types.useUpdateLocationEmergencyContactsMutation();

  useEffect(() => {
    setEmergencyContacts(general.currentLocation?.emergencyContacts || []);
  }, [general.currentLocation?.emergencyContacts]);

  //On save, Updates the current location with the new phone numbers in global state
  const updateCurrentLocationInState = (locationSelected: customTypes.AdminLocation) => {
    if (locationSelected?.level === "City") {
      dispatch(GeneralActionCreators.locationUpdate(locationSelected));
    } else if (locationSelected?.level === "Country") {
      dispatch(GeneralActionCreators.locationUpdate(locationSelected));
      dispatch(GeneralActionCreators.countryUpdate(locationSelected));
    }
  };

  //Adds a blank number when user clicks add new number
  const onAddNewNumber = () => {
    setEmergencyContacts(emergencyContacts.concat({ id: "", title: "", phoneNumber: "" }));
  };

  //On save, Updates numbers in amplify then ensures data is in sync locally
  const updateNumbersInCloudThenLocally = async () => {
    let error;
    setLoading(true);
    if (general.currentLocation && general.currentLocation.centreCoordinates) {
      try {
        const updatedLocation = {
          id: general.currentLocation.id,
          emergenecyContacts: emergencyContacts,
          location: general.currentLocation.centreCoordinates,
          name: general.currentLocation.name,
        };

        const updatedLocationRes = await updateLocationEmergencyContactsMutation({
          variables: updatedLocation,
        });
        if (updatedLocationRes?.data?.updateAdministrationLocation)
          updateCurrentLocationInState(updatedLocationRes.data.updateAdministrationLocation);
      } catch (e) {
        error = e.message;
      }
      if (!error) {
        navigation.navigate("CONFIRMATION", {
          confirmationText: "The emergency contact's have been updated",
          onContinue: () => navigation.pop(2),
        });
      } else {
        setError(error);
      }
    }
    setLoading(false);
  };

  //Updates emergency contact using hooks to reflect changes made by user
  const updateEmergencyContactCurrentlyBeingEdited = (
    id: string,
    updatedEmergencyContact: EmergencyContact
  ) => {
    const emergencyContact = emergencyContacts.find((e) => e.id === id);
    if (emergencyContact) {
      const indexToReplace = emergencyContacts?.indexOf(emergencyContact);
      emergencyContacts[indexToReplace] = updatedEmergencyContact;
    }
  };

  //On delete, Deletes number in amplify then ensures data is in sync locally
  const deleteEmergencyContact = async (id: string) => {
    let error;
    const emergencyContactsFiltered = emergencyContacts.filter((e) => e.id !== id);
    if (general.currentLocation && general.currentLocation.centreCoordinates) {
      try {
        const updatedLocationRes = await updateLocationEmergencyContactsMutation({
          variables: {
            id: general.currentLocation.id,
            emergenecyContacts: emergencyContactsFiltered,
            location: general.currentLocation.centreCoordinates,
            name: general.currentLocation.name,
          },
        });
        if (updatedLocationRes?.data?.updateAdministrationLocation)
          updateCurrentLocationInState(updatedLocationRes.data.updateAdministrationLocation);
      } catch (e) {
        error = e.message;
      }
      if (!error) {
        navigation.navigate("CONFIRMATION", {
          confirmationText: "The emergency contact has been deleted",
          onContinue: () => navigation.pop(2),
        });
      } else {
        setError(error);
      }
    }
  };

  //Component to allow editing of number, title and associated images with a delete button
  const renderAdminEmergencyContact: ListRenderItem<EmergencyContact> = ({ item }) => (
    <UpdateAdminEmergencyContact
      setEditingNumber={setEditingNumber}
      initialEmergencyContact={item}
      setEmergencyContacts={(id: string, updatedEmergencyContact: EmergencyContact) =>
        updateEmergencyContactCurrentlyBeingEdited(id, updatedEmergencyContact)
      }
      deleteEmergencyContact={deleteEmergencyContact}
    />
  );
  return (
    <Layout style={screenStyles.webCentredColumnNarrow}>
      <Card disabled style={screenStyles.screenMainSectionSoButtonsAtBottom}>
        <UpdatePageBlock title={"Confirm location of emergency contact *"}>
          <LocationSelectWithModalContainer onChange={() => null} />
          <Text style={{ marginVertical: 8 }}>
            Emergency contacts are the phone numbers that will appear to users (in the above
            location) when they click the emergency button.
          </Text>
          <List
            ItemSeparatorComponent={() => <Divider />}
            data={emergencyContacts}
            renderItem={renderAdminEmergencyContact}
          />
        </UpdatePageBlock>
      </Card>
      {error && <Callout text={error} status={"danger"} />}
      <Button
        style={screenStyles.buttonAtBottomOfForm}
        onPress={onAddNewNumber}
        status={"success"}
        disabled={editingNumber}
      >
        Add new number
      </Button>
      {/*Disabled save button if a number is being edited i.e. invalid*/}
      <Button
        appearance={loading ? "outline" : "filled"}
        accessoryRight={loading ? () => <Spinner status="primary" /> : undefined}
        style={screenStyles.buttonAtBottomOfForm}
        onPress={updateNumbersInCloudThenLocally}
        disabled={editingNumber}
      >
        Save Changes
      </Button>
    </Layout>
  );
};
