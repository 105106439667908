import React, { FC } from "react";

import { LoadingFullscreen } from "@h2c/common/src/molecules";
import { useAmplifyAuth } from "@h2c/service";

import { SignInScreen } from "../screens";

import { MainStack } from "./MainStack";

/**
 * Renders loading if waiting to find out about authentication
 * Sign In screen if not authenticated
 *
 * Loads available languages and render app if logged in
 * @constructor
 */
export const Router: FC = () => {
  const { loading, user } = useAmplifyAuth();

  if (loading) return <LoadingFullscreen />;
  else if (user) return <MainStack />;
  else return <SignInScreen />;
};
