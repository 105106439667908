import { Auth } from "@aws-amplify/auth";
import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import { RouteProp, useIsFocused } from "@react-navigation/native";
import React, { FC, useEffect, useState } from "react";

import { useAmplifyAuth } from "@h2c/service";
import {
  getAdminUsersInGroupFromCognito,
  USER_GROUPS,
} from "@h2c/service/src/adminActionApiFunctions";

import { TopBarDrawerScreens } from "../../molecules/TopBarDrawerScreens";
import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { UserType } from "../../types/cognitoidentityserviceprovider";

import { SettingsHomeVisuals } from "./SettingsHomeVisuals";

type SettingsContainerProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
  route: RouteProp<RouteParams, "ADMIN_SETTINGS">;
};

export const SettingsHomeContainer: FC<SettingsContainerProps> = ({ navigation }) => {
  const { signOut, user } = useAmplifyAuth();
  //Password reset
  const [loadingSignOut, setLoadingSignOut] = useState(false);
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [passwordChangeErrors, setPasswordChangeErrors] = useState<string[]>([]);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [secureTextEntry, setSecureTextEntry] = React.useState(true);

  const [admins, setAdmins] = useState<UserType[]>([]);
  const [loadingAdmins, setLoadingAdmins] = useState(false);
  const [superAdmins, setSuperAdmins] = useState<UserType[]>([]);
  const [loadingSuperAdmins, setLoadingSuperAdmins] = useState(false);

  const [currentUserGroups, setCurrentUserGroups] = useState<string[]>([]);

  const getAdmins = async () => {
    setLoadingAdmins(true);
    const admins: UserType[] = await getAdminUsersInGroupFromCognito(USER_GROUPS.ADMIN);
    setAdmins(admins);
    setLoadingAdmins(false);
  };

  const getSuperAdmins = async () => {
    setLoadingSuperAdmins(true);
    const superAdmins: UserType[] = await getAdminUsersInGroupFromCognito(USER_GROUPS.SUPER_ADMIN);
    setSuperAdmins(superAdmins);
    setLoadingSuperAdmins(false);
  };

  const getCurrentUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setCurrentUserGroups(user.signInUserSession.accessToken.payload["cognito:groups"]);
  };

  // Auto refetch admin lists on page refocus and update current user's groups in state
  const isFocused = useIsFocused();
  useEffect(() => {
    if ((isFocused && !loadingSuperAdmins) || loadingAdmins) {
      getAdmins();
      getSuperAdmins();
      getCurrentUser();
    }
  }, [isFocused]);

  const handleSignOut = async () => {
    setLoadingSignOut(true);
    await signOut();
    setLoadingSignOut(false);
  };

  const handleResetPassword = async () => {
    const errors = [];

    if (errors.length === 0) {
      setLoadingChangePassword(true);
      try {
        await Auth.changePassword(user, currentPassword, newPassword);
        setCurrentPassword("");
        setNewPassword("");
        setPasswordChangeErrors([]);
        navigation.navigate("CONFIRMATION", {
          confirmationText: "Your password has been updated",
          onContinue: () => navigation.navigate("ADMIN_SETTINGS"),
        });
      } catch (e) {
        errors.push(e.message);
      }
    }
    setPasswordChangeErrors(errors);
    setLoadingChangePassword(false);
  };

  return (
    <>
      <TopBarDrawerScreens navigation={navigation} />
      <SettingsHomeVisuals
        handleSignOut={handleSignOut}
        navigation={navigation}
        loadingSignOut={loadingSignOut}
        passwordChangeErrors={passwordChangeErrors}
        loadingChangePassword={loadingChangePassword}
        secureTextEntry={secureTextEntry}
        setSecureTextEntry={setSecureTextEntry}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        handleResetPassword={handleResetPassword}
        admins={admins}
        loadingAdmins={loadingAdmins}
        superAdmins={superAdmins}
        loadingSuperAdmins={loadingSuperAdmins}
        onAddAdmin={(isSuperAdmin: boolean) =>
          navigation.navigate("SETTINGS_ADD_ADMIN", { isSuperAdmin })
        }
        // TODO(JACK): Pass a user object
        onUpdateAdmin={(admin: UserType) =>
          navigation.navigate("SETTINGS_UPDATE_ADMIN", { admin: admin })
        }
        LocationSelectWithModal={() => <LocationSelectWithModalContainer onChange={() => null} />}
        onAddLocation={() => navigation.navigate("SETTINGS_ADD_LOCATION")}
        currentUserIsSuperAdmin={currentUserGroups && currentUserGroups.includes("superAdmin")}
        navigateToTutorialVideo={() => navigation.navigate("TUTORIAL_VIDEO")}
        onAbout={() => navigation.navigate("ABOUT")}
        onEditEmergencyContactsForLocation={() =>
          navigation.navigate("SETTINGS_UPDATE_EMERGENCY_CONTACTS")
        }
      />
    </>
  );
};
