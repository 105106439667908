import React from "react";

import { AudioProvider, ThemeProvider } from "@h2c/common";
import { isMobileDevice } from "@h2c/common/src/constants";

import { GlobalStateProvider } from "./providers/GlobalStateProvider";
import { LanguageProvider } from "./providers/LanguageProvider";
import { LoadStateProvider } from "./providers/LoadStateProvider";
import { Router } from "./routes/Router";

export default function App() {
  return (
    <LanguageProvider>
      <GlobalStateProvider>
        <ThemeProvider isMobileAdmin={isMobileDevice()}>
          <LoadStateProvider>
            <AudioProvider>
              <Router />
            </AudioProvider>
          </LoadStateProvider>
        </ThemeProvider>
      </GlobalStateProvider>
    </LanguageProvider>
  );
}
