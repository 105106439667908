import { StackNavigationProp } from "@react-navigation/stack/lib/typescript/src/types";
import { Button, Card, Layout, List, Spinner } from "@ui-kitten/components";
import React, { FC, useState } from "react";
import { ListRenderItem } from "react-native";

import { PhoneInput } from "@h2c/common/src/molecules";
import { Callout } from "@h2c/common/src/molecules/Callout";
import { EditText } from "@h2c/common/src/molecules/EditText";
import { EditType } from "@h2c/common/src/molecules/EditType";
import { UpdatePageBlock } from "@h2c/common/src/molecules/UpdatePageBlock";
import { ViewLocation } from "@h2c/common/src/molecules/ViewLocation";
import { InformationModal } from "@h2c/common/src/organisms";
import { screenStyles } from "@h2c/common/src/styles";
import { HelpSiteType } from "@h2c/service/graphql/types";
import {
  Coordinates,
  GenericDataItem,
  GenericDataItemDetailsType,
  UpdateHelpSiteProps,
} from "@h2c/service/src/dataItemManipulation";

import { RouteParams } from "../../routes";

type HelpSitesEditVisualsProps = {
  LocationSelectWithModal?: FC;
  dataItem: GenericDataItem<HelpSiteType>;
  onUpdate: (helpSite: UpdateHelpSiteProps<HelpSiteType>) => void;
  error?: string;
  loading: boolean;
  types: GenericDataItemDetailsType<HelpSiteType>[];
  setDataItemToDelete?: (dataItemId: string | null) => void;
  dataItemToDelete?: string | null;
  deleting?: boolean;
  errorDeleting?: string;
  deleteDataItem?: () => Promise<void>;
  navigation: StackNavigationProp<RouteParams, "HELP_SITE_UPDATE" | "HELP_SITE_CREATE">;
  pageLogoUrlOrId?: string;
};

/**
 * Visuals for update page where admin can make changes to COVID reports and Supply requests
 */
export const HelpSitesEditVisuals: FC<HelpSitesEditVisualsProps> = ({
  LocationSelectWithModal,
  dataItem,
  onUpdate,
  error,
  loading,
  types,
  deleteDataItem,
  setDataItemToDelete,
  deleting,
  errorDeleting,
  dataItemToDelete,
  navigation,
  pageLogoUrlOrId,
}) => {
  const [type, setType] = useState<HelpSiteType>(dataItem.type);
  const [location, setLocation] = useState<Coordinates>(dataItem.location);
  const [additionalInfo, setAdditionalInfo] = useState<string | undefined>(
    dataItem.additionalInfo || undefined
  );
  const [title, setTitle] = useState<string | undefined>(dataItem.title);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    dataItem.phoneNumber || undefined
  );
  const [organisationName, setOrganisationName] = useState<string | undefined>(
    dataItem.organisationName || undefined
  );
  const [emailAddress, setEmailAddress] = useState<string | undefined>(
    dataItem.emailAddress || undefined
  );

  const updateLocation = (newLocation?: Coordinates) => {
    if (newLocation) setLocation(newLocation);
    navigation.pop();
  };

  const logoImageUrlOrId = type === HelpSiteType.OrgLocation ? pageLogoUrlOrId : undefined;

  const renderTypeItem: ListRenderItem<GenericDataItemDetailsType<HelpSiteType>> = ({ item }) => (
    <EditType item={item} currentType={type} onPress={() => setType(item.type)} />
  );

  const navigateToChooseLocation = () => {
    navigation.navigate("LOCATION_CHOOSE", {
      onSubmit: updateLocation,
      initialPosition: location,
      icon: "clinic",
      custom: true,
      logoImageUrlOrId,
    });
  };

  return (
    <Layout style={screenStyles.webCentredColumnNarrow}>
      <Card disabled style={screenStyles.screenMainSectionSoButtonsAtBottom}>
        {LocationSelectWithModal && !pageLogoUrlOrId && (
          <UpdatePageBlock title={"Confirm city of Help Site *"}>
            <LocationSelectWithModal />
          </UpdatePageBlock>
        )}
        <UpdatePageBlock title={"Name of Help Site *"}>
          <EditText onPress={(text: string) => setTitle(text)} additionalInfo={title} />
        </UpdatePageBlock>

        <UpdatePageBlock title={`Type of ${dataItem.category} *`}>
          <List data={types} renderItem={renderTypeItem} />
        </UpdatePageBlock>

        <UpdatePageBlock
          editButton={true}
          onClickEditButton={navigateToChooseLocation}
          title={"Location *"}
        >
          <ViewLocation
            location={location}
            icon={"clinic"}
            onPress={navigateToChooseLocation}
            custom
            confirmed={types.find((t) => t.type === dataItem.type)?.confirmed || false}
            logoImageUrlOrId={logoImageUrlOrId}
          />
        </UpdatePageBlock>

        <UpdatePageBlock title={"Phone Number"}>
          <PhoneInput
            number={phoneNumber || ""}
            onChange={(text: string) => setPhoneNumber(text)}
            textStyle={{ minHeight: 32 }}
            hideLabel
          />
        </UpdatePageBlock>

        <UpdatePageBlock title={"Email"}>
          <EditText
            onPress={(text: string) => setEmailAddress(text)}
            additionalInfo={emailAddress}
          />
        </UpdatePageBlock>

        <UpdatePageBlock title={"Organisation"}>
          <EditText
            onPress={(text: string) => setOrganisationName(text)}
            additionalInfo={organisationName}
          />
        </UpdatePageBlock>

        <UpdatePageBlock title={"Additional Info"}>
          <EditText
            onPress={(text: string) => setAdditionalInfo(text)}
            additionalInfo={additionalInfo}
            multiline
          />
        </UpdatePageBlock>
      </Card>
      {setDataItemToDelete ? (
        <Button
          style={screenStyles.buttonAtBottomOfForm}
          status={"danger"}
          onPress={() => setDataItemToDelete(dataItem.id)}
        >
          Delete
        </Button>
      ) : null}

      {error ? (
        <Callout status={"danger"} icon={{ name: "exclamation-circle" }} text={error} />
      ) : null}

      <Button
        appearance={loading ? "outline" : "filled"}
        accessoryRight={loading ? () => <Spinner status="primary" /> : undefined}
        style={screenStyles.buttonAtBottomOfForm}
        onPress={() =>
          onUpdate({
            location,
            additionalInfo,
            id: dataItem.id,
            type,
            emailAddress,
            phoneNumber: phoneNumber?.trim(),
            title,
            organisationName,
            pageLogoUrlOrId: logoImageUrlOrId || null,
          })
        }
      >
        Save Changes
      </Button>

      {setDataItemToDelete && deleteDataItem ? (
        <InformationModal
          visibility={dataItemToDelete !== null}
          displayText={`Are you sure you want to delete this ${dataItem.category}?`}
          onCancel={() => setDataItemToDelete(null)}
          onSubmit={deleteDataItem}
          submitText={"Delete"}
          loading={deleting || false}
          error={errorDeleting}
        />
      ) : null}
    </Layout>
  );
};
