import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Button,
  Icon,
  IndexPath,
  Select,
  SelectItem,
  Text,
  TopNavigation,
  TopNavigationAction,
  useTheme,
} from "@ui-kitten/components";
import React, { FC, useState, useMemo } from "react";
import isEqual from "react-fast-compare";
import { View } from "react-native";

import { screenStyles } from "@h2c/common/src";
import { Callout } from "@h2c/common/src/molecules/Callout";
import { PageSelectBlockTypeModal } from "@h2c/common/src/molecules/PageSelectBlockTypeModal";
import { blockEditors } from "@h2c/common/src/page-parser/edit-components";
import { Block, ParagraphBlock } from "@h2c/common/src/page-parser/types";

import { RouteParams } from "../routes";

type EditBlockScreenProps = {
  navigation: StackNavigationProp<RouteParams, "PAGE_BLOCK_EDIT">;
  route: RouteProp<RouteParams, "PAGE_BLOCK_EDIT">;
};

export const PageBlockEditScreen: FC<EditBlockScreenProps> = ({ navigation, route }) => {
  // todo(arlyon): Can we make this whole screen generic on the route prop type?
  //               right now block is a 'Block' and not something that extends Block...
  const { block, onSave, onHelpSite } = route.params;
  const [editedBlock, setEditedBlock] = useState(
    block || ({ type: "paragraph", text: { source: "", translationIndex: 0 } } as ParagraphBlock)
  );
  const theme = useTheme();

  const selectedIndex = useMemo(() => {
    const index = Object.keys(blockEditors).findIndex((type) => type === editedBlock.type);
    return index !== -1 ? new IndexPath(index) : undefined;
  }, [editedBlock]);

  const originalEditor =
    block !== null ? blockEditors[block.type as Exclude<Block["type"], "location">] : null;
  const editor = blockEditors[editedBlock.type as Exclude<Block["type"], "location">];

  const onChange = <T extends Block>(block: T) => {
    setEditedBlock(block);
  };

  const changeBlockType = (type: Block["type"]) => {
    // @ts-ignore(ssp6)
    onChange({
      ...editedBlock, // change the block type while retaining all the old fields
      type: type,
    });
  };

  const onExit = () => {
    if (!isEqual(block, editedBlock)) onSave(editedBlock);
    navigation.pop();
  };

  let Component;
  let selectedName;
  if (editor === undefined) {
    Component = <Text>Trying to edit a block type that doesn't exist.</Text>;
  } else {
    selectedName = editor.name;
    const Editor = editor.component;
    // @ts-ignore(arlyon): Block is unknown because screens are not generic...
    Component = <Editor block={editedBlock} onChange={onChange} onHelpSite={onHelpSite} />;
  }

  return (
    <>
      <TopNavigation
        title="Editing Block"
        accessoryLeft={(props) => (
          <TopNavigationAction
            {...props}
            icon={(props) => <Icon {...props} name="arrow-left" />}
            onPress={onExit}
          />
        )}
      />
      <View style={{ backgroundColor: theme["color-primary-700"], padding: 16 }}>
        {/*Change block type — can't change whilst changing any page blocks or helpSite block*/}
        {block?.type === "page" || block?.type === "helpSite" ? null : ( // Can't change type whilst editing page logo/title
          <View style={{ maxWidth: 800, marginHorizontal: "auto", width: "100%" }}>
            <Select
              status={"control"}
              selectedIndex={selectedIndex}
              onSelect={(index) => changeBlockType(getEditorByIndex(index))}
              placeholder="Unknown"
              value={selectedName}
            >
              {Object.entries(blockEditors)
                .filter(([key]) => key !== "page")
                .map(([, editor], index) => (
                  <SelectItem key={index} title={editor.name} />
                ))}
            </Select>
          </View>
        )}
      </View>
      <View style={[screenStyles.webCentredColumn, { marginVertical: 32 }]}>
        <Text>{editor.extraDesc}</Text>
        {originalEditor !== null && block?.type !== editedBlock.type && (
          <Callout
            text={`Changing from a ${originalEditor.name} to a ${editor.name}.`}
            status="warning"
          />
        )}
        {Component}
        <Button onPress={onExit}>NEXT</Button>
      </View>
      {block === null && (
        <PageSelectBlockTypeModal onBlockSelect={(type) => changeBlockType(type)} />
      )}
    </>
  );
};

const getEditorByIndex = (index: IndexPath | IndexPath[]): Block["type"] =>
  Object.keys(blockEditors)[Array.isArray(index) ? index[0].row : index.row] as Block["type"];
