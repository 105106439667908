import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useEffect, useState } from "react";

import { LoadingFullscreen } from "@h2c/common/src/molecules";
import {
  addOrRemoveUserFromGroup,
  createNewAdminProps,
  deleteAdmin,
  disableEnableUser,
  getGroupsOfCurrentUser,
  USER_GROUPS,
} from "@h2c/service/src/adminActionApiFunctions";

import { RouteParams } from "../../routes";
import { AdminListGroupsForUserResponse } from "../../types/cognitoidentityserviceprovider";

import { SettingsEditAdminVisuals } from "./SettingsEditAdminVisuals";

type SettingsUpdateAdminContainerProps = {
  navigation: StackNavigationProp<RouteParams, "SETTINGS_UPDATE_ADMIN">;
  route: RouteProp<RouteParams, "SETTINGS_UPDATE_ADMIN">;
};

export const SettingsUpdateAdminContainer: FC<SettingsUpdateAdminContainerProps> = ({
  route,
  navigation,
}) => {
  const [updatingAdmin, setUpdatingAdmin] = useState(false);
  const [disablingEnabling, setDisablingEnabling] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletingError, setDeletingError] = useState();
  const [updateAdminError, setUpdateAdminError] = useState();
  const [disableEnableError, setDisableEnableError] = useState();
  const [loadingUserDetails, setLoadingUserDetails] = useState(true);
  const [superAdmin, setSuperAdmin] = useState<boolean>(false);
  const [closeAllModals, setCloseAllModals] = useState<boolean>(false);

  useEffect(() => {
    getGroupsAdminBelongsTo();
  }, []);

  const updateAdminGroup = async (group: USER_GROUPS, username: string) => {
    if (group === USER_GROUPS.SUPER_ADMIN && !superAdmin) {
      await addOrRemoveUserFromGroup(false, USER_GROUPS.ADMIN, username);
      await addOrRemoveUserFromGroup(true, USER_GROUPS.SUPER_ADMIN, username);
    } else if (group === USER_GROUPS.ADMIN && USER_GROUPS.SUPER_ADMIN) {
      await addOrRemoveUserFromGroup(false, USER_GROUPS.SUPER_ADMIN, username);
      await addOrRemoveUserFromGroup(true, USER_GROUPS.ADMIN, username);
    }
  };

  const onUpdate = async (props: createNewAdminProps) => {
    setUpdatingAdmin(true);
    if (route.params.admin.Username) {
      try {
        await updateAdminGroup(props.group, route.params.admin.Username);
      } catch (e) {
        setUpdateAdminError(e.message);
      }
    }

    setUpdatingAdmin(false);
    navigation.navigate("CONFIRMATION", {
      confirmationText: "The admin's information has been updated",
      onContinue: () => navigation.navigate("ADMIN_SETTINGS"),
    });
  };

  //Toggles the accounts disabled status depending on the accounts current status
  const onDisableEnable = async () => {
    setDisablingEnabling(true);
    if (route.params.admin.Username) {
      try {
        await disableEnableUser(!route.params.admin.Enabled, route.params.admin.Username);
      } catch (e) {
        setDisableEnableError(e.message);
      }
    }

    setDisablingEnabling(false);
    setCloseAllModals(true);
    navigation.navigate("CONFIRMATION", {
      confirmationText: "The admin's information has been updated",
      onContinue: () => navigation.navigate("ADMIN_SETTINGS"),
    });
  };

  const onDelete = async () => {
    setDeleting(true);
    if (route.params.admin.Username) {
      try {
        await deleteAdmin(route.params.admin.Username);
      } catch (e) {
        setDeletingError(e.message);
      }
    }

    setDeleting(false);
    setCloseAllModals(true);
    navigation.navigate("CONFIRMATION", {
      confirmationText: "The admin has been deleted",
      onContinue: () => navigation.navigate("ADMIN_SETTINGS"),
    });
  };

  const getGroupsAdminBelongsTo = async () => {
    let groups: AdminListGroupsForUserResponse = { Groups: [] };
    if (route.params.admin.Username) {
      groups = await getGroupsOfCurrentUser(route.params.admin.Username);
    }
    setSuperAdmin(groups?.Groups?.find((group) => group.GroupName === "superAdmin") !== undefined);
    setLoadingUserDetails(false);
  };

  return loadingUserDetails ? (
    <LoadingFullscreen />
  ) : (
    <SettingsEditAdminVisuals
      initialEmail={
        route?.params?.admin?.Attributes?.[route?.params?.admin?.Attributes.length - 1].Value
      }
      onSubmit={onUpdate}
      savingAdmin={updatingAdmin}
      saveAdminError={updateAdminError}
      checkSuperAdmin={superAdmin}
      onDisableEnable={onDisableEnable}
      disableEnableError={disableEnableError}
      showDisable={route.params.admin.Enabled}
      disablingEnabling={disablingEnabling}
      onDelete={onDelete}
      deleting={deleting}
      deletingError={deletingError}
      closeAllModals={closeAllModals}
    />
  );
};
