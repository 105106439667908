import { Reducer } from "react";

import {
  customTypes,
  DEFAULT_ANNOUNCEMENT_CONTENT,
  DEFAULT_ANNOUNCEMENT_TITLE,
} from "@h2c/service";
import { CreateAnnouncementMutationVariables } from "@h2c/service/graphql/types";

enum GeneralActions {
  LOADING,
  COMPLETE_WELCOME_FLOW,
  UPDATE_ANNOUNCEMENT,
  LOCATION_UPDATE,
  COUNTRY_UPDATE,
}

export type SingleAction<T, P> = { type: T; payload: P };

export type GeneralActionType = ReturnType<
  typeof GeneralActionCreators[keyof typeof GeneralActionCreators]
>;

export type GeneralState = {
  loading: boolean;
  completeWelcomeFlow: boolean;
  currentAnnouncement: CreateAnnouncementMutationVariables;
  currentLocation?: customTypes.AdminLocation;
  currentCountry?: customTypes.AdminLocation;
};

export const ADMIN_GENERAL_DEFAULT_STATE: GeneralState = {
  loading: false,
  completeWelcomeFlow: false,
  currentAnnouncement: {
    title: DEFAULT_ANNOUNCEMENT_TITLE,
    content: DEFAULT_ANNOUNCEMENT_CONTENT,
    audio: [],
    text: [],
  },
  currentLocation: undefined,
  currentCountry: undefined,
};

export const adminGeneralReducer: Reducer<GeneralState, GeneralActionType> = (oldState, action) => {
  let state;
  switch (action.type) {
    case GeneralActions.LOADING:
      state = {
        ...oldState,
        loading: action.payload.loading,
      };
      break;
    case GeneralActions.COMPLETE_WELCOME_FLOW:
      state = {
        ...oldState,
        completeWelcomeFlow: action.payload,
      };
      break;
    case GeneralActions.UPDATE_ANNOUNCEMENT:
      state = {
        ...oldState,
        currentAnnouncement: action.payload,
      };
      break;
    case GeneralActions.LOCATION_UPDATE:
      state = {
        ...oldState,
        currentLocation: action.payload,
      };
      break;
    case GeneralActions.COUNTRY_UPDATE:
      state = {
        ...oldState,
        currentCountry: action.payload,
      };
      break;
  }
  return state;
};

const createAction = <T extends GeneralActions, P>(type: T, payload: P): SingleAction<T, P> => ({
  type,
  payload,
});

export const GeneralActionCreators = {
  loading: (loading: boolean) => createAction(GeneralActions.LOADING, { loading }),
  completeWelcomeFlow: (complete: boolean) =>
    createAction(GeneralActions.COMPLETE_WELCOME_FLOW, complete),
  updateAnnouncement: (announcement: CreateAnnouncementMutationVariables) =>
    createAction(GeneralActions.UPDATE_ANNOUNCEMENT, announcement),
  locationUpdate: (location: customTypes.AdminLocation) =>
    createAction(GeneralActions.LOCATION_UPDATE, location),
  countryUpdate: (country: customTypes.AdminLocation) =>
    createAction(GeneralActions.COUNTRY_UPDATE, country),
};
