import { StackNavigationProp } from "@react-navigation/stack/lib/typescript/src/types";
import { Button, Card, Layout, List, Spinner } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { ListRenderItem } from "react-native";

import {
  EditStatus,
  EditText,
  EditType,
  UpdatePageBlock,
  ViewLocation,
} from "@h2c/common/src/molecules";
import { InformationModal } from "@h2c/common/src/organisms/InformationModal";
import { screenStyles } from "@h2c/common/src/styles";
import { RequestStatus } from "@h2c/service/graphql/types";
import {
  GenericDataItem,
  GenericDataItemDetailsType,
  Coordinates,
  REPORT_STATUS_WITH_DETAILS,
  StatusWithDetailsType,
} from "@h2c/service/src/dataItemManipulation";

import { RouteParams } from "../routes";

export type UpdateReportProps<T> = {
  location?: Coordinates;
  additionalInfo?: string;
  status?: RequestStatus;
  id: string;
  type?: T;
};

type ReportUpdateVisualsProps<T> = {
  dataItem: GenericDataItem<T>;
  onUpdate: (report: UpdateReportProps<T>) => void;
  error?: string;
  loading: boolean;
  types: GenericDataItemDetailsType<T>[];
  setDataItemToDelete: (dataItemId: string | null) => void;
  dataItemToDelete: string | null;
  deleting: boolean;
  errorDeleting?: string;
  deleteDataItem: () => Promise<void>;
  navigation: StackNavigationProp<RouteParams, "INCIDENT_UPDATE">;
};

/**
 * Visuals for update page where admin can make changes to COVID reports and Supply requests
 */
export const ReportUpdateVisuals = <T,>({
  dataItem,
  onUpdate,
  loading,
  types,
  deleteDataItem,
  setDataItemToDelete,
  deleting,
  errorDeleting,
  dataItemToDelete,
  navigation,
}: ReportUpdateVisualsProps<T>) => {
  const [type, setType] = useState<T>();
  const [location, setLocation] = useState<Coordinates>();
  const [additionalInfo, setAdditionalInfo] = useState<string>();
  const [status, setStatus] = useState<RequestStatus>();

  useEffect(() => {
    setLocation(dataItem.location);
    if (dataItem.status) setStatus(dataItem.status);
    if (dataItem.additionalInfo) setAdditionalInfo(dataItem.additionalInfo);
    if (dataItem.type) setType(dataItem.type);
  }, []);

  const renderStatusItem: ListRenderItem<StatusWithDetailsType> = ({ item }) => (
    <EditStatus
      onPress={() => setStatus(item.type)}
      item={item}
      currentStatus={status || RequestStatus.Open}
    />
  );

  const renderTypeItem: ListRenderItem<GenericDataItemDetailsType<T>> = ({ item }) => (
    <EditType item={item} currentType={type} onPress={() => setType(item.type)} />
  );

  const updateLocation = (newLocation?: Coordinates) => {
    if (newLocation) setLocation(newLocation);
    navigation.pop();
  };

  const navigateToChooseLocation = () => {
    navigation.navigate("LOCATION_CHOOSE", {
      onSubmit: updateLocation,
      initialPosition: location,
      icon: "exclamation-circle",
      custom: false,
    });
  };

  return (
    <Layout style={screenStyles.webCentredColumnNarrow}>
      <Card disabled style={screenStyles.screenMainSectionSoButtonsAtBottom}>
        <UpdatePageBlock title={`Type of ${dataItem.category}`}>
          <List data={types} renderItem={renderTypeItem} />
        </UpdatePageBlock>

        <UpdatePageBlock
          editButton={true}
          onClickEditButton={navigateToChooseLocation}
          title={"Location *"}
        >
          <ViewLocation
            location={location}
            icon={"exclamation-circle"}
            onPress={navigateToChooseLocation}
            custom={false}
            confirmed={types.find((t) => t.type === dataItem.type)?.confirmed || false}
          />
        </UpdatePageBlock>

        <UpdatePageBlock title={"Additional Info"}>
          <EditText
            onPress={(text: string) => setAdditionalInfo(text)}
            additionalInfo={additionalInfo}
            multiline
          />
        </UpdatePageBlock>

        <UpdatePageBlock title={"Status"}>
          <List data={REPORT_STATUS_WITH_DETAILS} renderItem={renderStatusItem} />
        </UpdatePageBlock>
      </Card>
      <Button
        style={screenStyles.buttonAtBottomOfForm}
        status={"danger"}
        onPress={() => setDataItemToDelete(dataItem.id)}
      >
        Delete
      </Button>
      <Button
        appearance={loading ? "outline" : "filled"}
        accessoryRight={loading ? () => <Spinner status="primary" /> : undefined}
        style={screenStyles.buttonAtBottomOfForm}
        onPress={() =>
          onUpdate({
            location,
            additionalInfo,
            id: dataItem.id,
            type,
            status,
          })
        }
      >
        Save Changes
      </Button>

      <InformationModal
        visibility={dataItemToDelete !== null}
        displayText={`Are you sure you want to delete this ${dataItem.category}?`}
        onCancel={() => setDataItemToDelete(null)}
        onSubmit={deleteDataItem}
        submitText={"Delete"}
        loading={deleting}
        error={errorDeleting}
        submitStatus={"danger"}
      />
    </Layout>
  );
};
