import { Input, Layout, Spinner, Text, Button } from "@ui-kitten/components";
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

import { FlagAvatar } from "@h2c/common/src/atoms";
import { Callout, FILE_TYPES, FilePicker } from "@h2c/common/src/molecules";
import { uploadFileToS3 } from "@h2c/common/src/util";
import { EmergencyContact } from "@h2c/service/graphql/types";

type AdminEmergencyContactProps = {
  initialEmergencyContact: EmergencyContact;
  setEmergencyContacts: (id: string, updatedEmergencyContact: EmergencyContact) => void;
  deleteEmergencyContact: (id: string) => void;
  setEditingNumber: Dispatch<SetStateAction<boolean>>;
};

//Component to allow editing of number, title and associated images with a delete button
export const UpdateAdminEmergencyContact: FC<AdminEmergencyContactProps> = ({
  initialEmergencyContact,
  setEmergencyContacts,
  deleteEmergencyContact,
  setEditingNumber,
}) => {
  const [emergencyContact, setEmergencyContact] = useState<EmergencyContact>();
  const [uploadImageLoading, setUploadImageLoading] = useState<boolean>(false);
  const [imageUri, setImageUri] = useState<string>();
  const [currentNumberError, setCurrentNumberError] = useState<string>();
  const [deleting, setDeleting] = useState<boolean>(false);

  //Checks validity of phone number every time user makes a change
  useEffect(() => {
    if (isValidPhoneNumber(emergencyContact?.phoneNumber)) {
      setEditingNumber(false);
      setCurrentNumberError(undefined);
    } else {
      setEditingNumber(true);
      setCurrentNumberError("Phone number is not valid");
    }
  }, [emergencyContact?.phoneNumber]);

  useEffect(() => {
    setEmergencyContact(initialEmergencyContact);
  }, [initialEmergencyContact]);

  //Updates emergency contact title within component on title change
  const updateTitle = (newTitle: string) => {
    if (emergencyContact) {
      const newEmergencyContact = { ...emergencyContact, title: newTitle };

      setEmergencyContacts(emergencyContact.id, newEmergencyContact);
      setEmergencyContact(newEmergencyContact);
    }
  };

  //Updates emergency contact number and id within component on number change
  const updatePhoneNumber = (newPhoneNumber: string) => {
    if (emergencyContact) {
      const newEmergencyContact = {
        ...emergencyContact,
        id: newPhoneNumber,
        phoneNumber: newPhoneNumber,
      };

      setEmergencyContacts(emergencyContact.id, newEmergencyContact);
      setEmergencyContact(newEmergencyContact);
    }
  };

  //  //Updates iamge uri contact within component on upload of image
  useEffect(() => {
    if (imageUri && emergencyContact) {
      const newEmergencyContact = {
        ...emergencyContact,
        imageUri: imageUri,
      };

      setEmergencyContacts(emergencyContact.id, newEmergencyContact);
      setEmergencyContact(newEmergencyContact);
    }
  }, [imageUri]);

  //Uploads image to S3
  const uploadImage = async (localUri: string) => {
    setUploadImageLoading(true);
    try {
      const result = await uploadFileToS3(localUri, `language-flag-${emergencyContact?.title}`);
      setImageUri(result);
    } catch (e) {
      setCurrentNumberError(e.message);
    }

    setUploadImageLoading(false);
  };

  const deleteNumber = () => {
    setDeleting(true);
    deleteEmergencyContact(emergencyContact?.id || "");
    setDeleting(false);
  };

  return (
    <Layout style={{ padding: 10, margin: 10, top: 10 }}>
      <Layout style={{ margin: 5 }}>
        <Input
          size={"medium"}
          label={"Emergency contact label *"}
          onChangeText={updateTitle}
          value={emergencyContact?.title}
        />
      </Layout>
      <Layout style={{ margin: 5 }}>
        <Text category={"label"}>Phone number *</Text>
        <PhoneInput onChange={updatePhoneNumber} value={emergencyContact?.phoneNumber || ""} />
      </Layout>

      <Layout style={{ margin: 5 }}>
        <Text category={"label"}>Image</Text>
        <Layout style={styles.row}>
          {uploadImageLoading ? (
            <Spinner status="primary" />
          ) : (
            <>
              <FilePicker
                onGetLocalUriToFile={(uri) => uploadImage(uri)}
                fileType={FILE_TYPES.IMAGES}
                buttonText={"Upload Image"}
                onError={setCurrentNumberError}
              />
              <FlagAvatar
                size={62}
                flagUriOrRequireNumber={emergencyContact?.imageUri || ""}
                style={{ marginHorizontal: 8 }}
              />
            </>
          )}
        </Layout>
        {currentNumberError && <Callout text={currentNumberError} status={"danger"} />}
      </Layout>
      <Button
        status={"danger"}
        onPress={deleteNumber}
        appearance={deleting ? "outline" : "filled"}
        accessoryRight={deleting ? () => <Spinner status="primary" /> : undefined}
      >
        Delete number
      </Button>
    </Layout>
  );
};

const styles = StyleSheet.create({
  row: {
    alignItems: "center",
    flexDirection: "row",
  },
});
