import { createDrawerNavigator } from "@react-navigation/drawer";
import { DrawerContentComponentProps } from "@react-navigation/drawer/lib/typescript/src/types";
import { DrawerItem, Icon, Drawer, IndexPath, TopNavigation } from "@ui-kitten/components";
import React, { FC } from "react";

import { screenStyles } from "@h2c/common/src";
import { isMobileDevice } from "@h2c/common/src/constants";

import { LanguagesHomeScreen, HelpSiteMapScreen, PagesScreen, SettingsScreen } from "../screens";

import { CovidReportTabs } from "./CovidReportTabs";
import { IncidentsTabs } from "./IncidentsTabs";
import { SupplyRequestTabs } from "./SupplyRequestTabs";
import { RouteParams } from "./types";

const { Navigator, Screen } = createDrawerNavigator<RouteParams>();

const DrawerMenuTopBarOnWeb = () => (
  <TopNavigation
    style={screenStyles.topBar}
    alignment={"center"}
    title={"HelpToConnect"}
    subtitle={"Admin site"}
  />
);

const DrawerContent: FC<DrawerContentComponentProps> = ({ navigation, state }) => (
  <>
    {!isMobileDevice() && <DrawerMenuTopBarOnWeb />}
    <Drawer
      selectedIndex={new IndexPath(state.index)}
      onSelect={(index) => navigation.navigate(state.routeNames[index.row])}
    >
      <DrawerItem title="Information Pages" accessoryLeft={() => <Icon name={"file-alt"} />} />
      <DrawerItem
        title="Help Sites"
        accessoryLeft={() => <Icon name={"clinic"} pack={"custom"} />}
      />
      <DrawerItem title="Languages" accessoryLeft={() => <Icon name={"language"} />} />
      <DrawerItem
        title="Incidents"
        accessoryLeft={() => <Icon pack={"custom"} name={"trouble"} />}
      />
      <DrawerItem
        title="Settings"
        accessoryLeft={() => <Icon pack={"custom"} name={"settings"} />}
      />
    </Drawer>
  </>
);

export function DrawerMenu() {
  return (
    <Navigator
      drawerContent={(props) => <DrawerContent {...props} />}
      drawerType={isMobileDevice() ? "front" : "permanent"}
      drawerStyle={{ maxWidth: 200, borderRightWidth: 0 }}
    >
      <Screen name="INFORMATION_PAGES" component={PagesScreen} />
      <Screen name="HELP_SITES" component={HelpSiteMapScreen} />
      <Screen name="LANGUAGES_HOME" component={LanguagesHomeScreen} />
      <Screen name="INCIDENTS" component={IncidentsTabs} />
      <Screen name="ADMIN_SETTINGS" component={SettingsScreen} />
      {/*COVID 19 Routes - No longer required, kept in for typing*/}
      <Screen name="REQUESTS" component={SupplyRequestTabs} />
      <Screen name="REPORTS" component={CovidReportTabs} />
    </Navigator>
  );
}
