import { Button, Card, Icon, Layout, Spinner, Text } from "@ui-kitten/components";
import React, { FC, useEffect, useState } from "react";
import { View } from "react-native";

import { BUNDLED_TEXT_LANGS, custom18n, REFUGEE_APP_STATIC_TEXT } from "@h2c/common/src/i18n";
import { SourceTextType, SourceTextTypeGuaranteeAllKeys } from "@h2c/common/src/i18n/static-text";
import { DeleteConfirmationModal, TranslationInput } from "@h2c/common/src/molecules";
import { Callout } from "@h2c/common/src/molecules/Callout";
import { InformationModal } from "@h2c/common/src/organisms";
import { LanguageEditSection } from "@h2c/common/src/organisms";
import { screenStyles } from "@h2c/common/src/styles";

type LanguageEditIncludingStaticVisualsProps = {
  onSave: (userTranslations: SourceTextType) => void;
  errorsTranslations: null | string;
  translations: SourceTextType;
  sourceText: SourceTextTypeGuaranteeAllKeys;
  loading: boolean;
  languageName: string;
  flagImageUri: string;
  saveLanguageDetails: (name: string) => void;
  languageSaveLoading: boolean;
  uploadFlagImage: (localUri: string, name: string) => void;
  flagUploadLoading: boolean;
  languageDetailsErrors: string | null;
  setLanguageDetailsError: (error: string) => void;
  confirmationOfUpdatingLangModalVisible: boolean;
  closeConfirmationModal: () => void;
  updateAudio?: boolean;
  deleteModalVisible: boolean;
  setDeleteModalVisible: (visible: boolean) => void;
  deleteLang: () => Promise<void>;
  deletingError?: string;
  deletingLoading?: boolean;
  openEditVideoVoiceOvers: () => void;
};

export const LanguageEditIncludingStaticVisuals: FC<LanguageEditIncludingStaticVisualsProps> = ({
  onSave,
  errorsTranslations,
  translations,
  loading,
  languageName,
  sourceText,
  flagImageUri,
  saveLanguageDetails,
  languageSaveLoading,
  uploadFlagImage,
  flagUploadLoading,
  languageDetailsErrors,
  setLanguageDetailsError,
  confirmationOfUpdatingLangModalVisible,
  closeConfirmationModal,
  updateAudio,
  deleteModalVisible,
  setDeleteModalVisible,
  deleteLang,
  deletingError,
  deletingLoading,
  openEditVideoVoiceOvers,
}) => {
  const [userTranslations, setUserTranslations] = useState<
    { [key in REFUGEE_APP_STATIC_TEXT]?: string }
  >({});

  useEffect(() => {
    setUserTranslations(translations);
  }, [translations]);

  const updateUserTranslations = (key: REFUGEE_APP_STATIC_TEXT, value?: string) => {
    // setUserTranslations({ ...userTranslations, [key]: value }); // TOO SLOW
    // TODO(ssp6): Don't mutate state directly - ui updates because ui is not based on state
    // But the above standard method of ...Object is far too slow on large objects
    userTranslations[key] = value;
  };

  const error = errorsTranslations || deletingError;

  return (
    <Layout style={screenStyles.webCentredColumn}>
      <LanguageEditSection
        headingText={"Edit Language"}
        languageName={languageName}
        flagImageUri={flagImageUri}
        saveButtonText={"Update Language"}
        saveAction={saveLanguageDetails}
        saveLoading={languageSaveLoading}
        uploadLanguageImage={uploadFlagImage}
        uploadImageLoading={flagUploadLoading}
        error={languageDetailsErrors}
        setError={setLanguageDetailsError}
      />
      {/*Tutorial videos have been removed for time being*/}
      {/*{updateAudio && (*/}
      {/*  <Button*/}
      {/*    appearance={"outline"}*/}
      {/*    accessoryLeft={(props) => <Icon name="video" pack="solid" {...props} />}*/}
      {/*    style={screenStyles.verticalMargin}*/}
      {/*    onPress={() => openEditVideoVoiceOvers()}*/}
      {/*  >*/}
      {/*    Edit Tutorial Video Audio*/}
      {/*  </Button>*/}
      {/*)}*/}

      <Card disabled>
        <Text category={"h4"}>Translations</Text>

        {/*Explainer Text*/}
        <View style={screenStyles.verticalMargin}>
          <Text>Below is text that is in the app, such as titles and descriptions.</Text>
          <Text>
            Add and edit translations in {languageName} so that users who have selected it as their{" "}
            {updateAudio ? "audio" : "text"} language will be presented with the translation
          </Text>
        </View>

        {/*Translations Inputs*/}
        {Object.keys(custom18n.translations[BUNDLED_TEXT_LANGS.EN]).map((staticTextKey) => (
          <TranslationInput
            translation={translations[staticTextKey as REFUGEE_APP_STATIC_TEXT] || ""}
            source={
              sourceText[staticTextKey as REFUGEE_APP_STATIC_TEXT] || "English text not available"
            }
            key={staticTextKey}
            languageName={languageName}
            onUpdate={updateUserTranslations}
            staticTextKey={staticTextKey as REFUGEE_APP_STATIC_TEXT}
            audio={updateAudio}
          />
        ))}
      </Card>
      {error && <Callout text={error} status={"danger"} />}
      <Button
        style={screenStyles.bottomButton}
        appearance={loading ? "outline" : "filled"}
        onPress={() => onSave(userTranslations)}
        accessoryRight={loading ? () => <Spinner status="primary" /> : undefined}
      >
        Save Translations
      </Button>
      <Button status={"danger"} onPress={() => setDeleteModalVisible(true)}>
        Delete Language
      </Button>
      <DeleteConfirmationModal
        visible={deleteModalVisible}
        hideModal={() => setDeleteModalVisible(false)}
        explainerText={
          "Deleting this language will remove all translation data and cannot be restored"
        }
        confirmationString={languageName}
        onDelete={deleteLang}
        deleteLoading={deletingLoading}
      />
      <InformationModal
        visibility={confirmationOfUpdatingLangModalVisible}
        displayText={`Language details have been updated!`}
        onSubmit={closeConfirmationModal}
        cancelText={"Continue Editing"}
        onCancel={closeConfirmationModal}
        loading={false}
      />
    </Layout>
  );
};
