import { createContext, Dispatch as ReactDispatch } from "react";

import { GeneralActionType, GeneralState, SingleAction } from "./general";

export type CombinedState = {
  general: GeneralState;
};

export type CombinedReducerType = (
  state: CombinedState,
  action: SingleAction<any, any>
) => CombinedState;

type CombinedActions = GeneralActionType;

export type Dispatch = ReactDispatch<CombinedActions>;

export const GlobalStateContext = createContext([{}, {}] as [CombinedState, Dispatch]);
