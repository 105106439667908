import { Storage } from "@aws-amplify/storage";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useContext, useState } from "react";

import { DEFAULT_ANNOUNCEMENT_TITLE, getAudioTranslation } from "@h2c/service";

import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";
import { GeneralActionCreators } from "../../state/general";

import { AddAudioTranslationVisuals } from "./AddAudioTranslationVisuals";

type AddAudioTranslationContainerProps = {
  navigation: StackNavigationProp<RouteParams, "ANNOUNCEMENT_ADD_AUDIO_TRANSLATION">;
  route: RouteProp<RouteParams, "ANNOUNCEMENT_ADD_AUDIO_TRANSLATION">;
};

//TODO(Jack): Figure out why S3 403 error not being handled correctly

/**
 * Allows a user to record audio or select an audio file,
 * uploading it to S3, before navigating back to the previous page.
 */
export const AnnouncementAddAudioTranslationContainer: FC<AddAudioTranslationContainerProps> = ({
  navigation,
  route,
}) => {
  const [state, dispatch] = useContext(GlobalStateContext);
  const [uploading, setUploading] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [selectedRecordingUri, setSelectedRecordingUri] = useState<string>();

  const getFileFromLocalPath = async (pathToImageFile: string): Promise<Blob | undefined> => {
    try {
      return await (await fetch(pathToImageFile)).blob();
    } catch (e) {
      setUploadErrors(["Failed to load audio file"]);
    }
  };

  const uploadAudioToS3 = async (blob: Blob | void): Promise<{ key: string } | null> => {
    try {
      const result = await Storage.put(
        `announcement-translation-${new Date().getTime()}.m4a`,
        blob
      );

      // we know this from the docs:
      // https://github.com/aws-amplify/docs/blob/master/docs/lib/storage/fragments/js/upload.md#put
      return result as { key: string };
    } catch (e) {
      setUploadErrors(["Failed to Upload"]);
      return null;
    }
  };

  const uploadAudioFile = async () => {
    if (selectedRecordingUri) {
      const blob = await getFileFromLocalPath(selectedRecordingUri).catch(() =>
        setUploadErrors(["Failed to load file"])
      );

      if (blob) {
        setUploading(true);
        const uploadedAudio = await uploadAudioToS3(blob);
        setUploading(false);

        if (uploadedAudio) {
          const key = uploadedAudio.key;

          const stateAudioWithoutCurrLang = state.general.currentAnnouncement.audio.filter(
            (t) => t.langID !== route.params.language.id
          );
          dispatch(
            GeneralActionCreators.updateAnnouncement({
              ...state.general.currentAnnouncement,
              audio: [
                ...stateAudioWithoutCurrLang,
                {
                  id: route.params.language.id,
                  langID: route.params.language.id,
                  outOfDate: false,
                  translations: [
                    {
                      translationIndex: DEFAULT_ANNOUNCEMENT_TITLE.translationIndex,
                      audio: key,
                    },
                  ],
                },
              ],
            })
          );

          navigation.navigate("UPLOAD_ANNOUNCEMENT");
        }
      }
    } else setUploadErrors(["No file selected"]);
  };

  const currentTranslationAudio = getAudioTranslation(
    DEFAULT_ANNOUNCEMENT_TITLE,
    state.general.currentAnnouncement.audio,
    route.params.language.id
  );

  return (
    <AddAudioTranslationVisuals
      languageName={route.params.language.name}
      sourceTitle={state.general.currentAnnouncement.title.source}
      sourceBody={state.general.currentAnnouncement.content.source}
      error={uploadErrors.join(", ")}
      upload={uploadAudioFile}
      uploading={uploading}
      setSelectedRecordingUri={setSelectedRecordingUri}
      selectedRecordingUri={selectedRecordingUri || null}
      currentTranslationAudio={currentTranslationAudio || undefined}
    />
  );
};
