import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Layout, Button, Text } from "@ui-kitten/components";
import GoogleMapReact from "google-map-react";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { geocodeByAddress } from "react-places-autocomplete";

import { screenStyles } from "@h2c/common/src";
import { MapIcon, Size } from "@h2c/common/src/atoms/MapIcon";
import { PENANG_COORDINATES, WEB_MAP_API_KEY } from "@h2c/common/src/constants";
import { isMobileDevice } from "@h2c/common/src/constants";
import { LocationAutoComplete } from "@h2c/common/src/molecules";
import { Coordinates } from "@h2c/service/src/dataItemManipulation";

import { RouteParams } from "../routes";
import { GlobalStateContext } from "../state";

type ChooseLocationProps<T> = {
  route: RouteProp<RouteParams, "LOCATION_CHOOSE">;
  navigation: StackNavigationProp<RouteParams, "LOCATION_CHOOSE">;
};

/**
 * Map for displaying a series of selectable icons on web
 * @constructor
 */
export const ChooseLocationScreen = <T,>({ route }: ChooseLocationProps<T>) => {
  const { initialPosition, onSubmit, custom, icon, logoImageUrlOrId } = route.params;
  const [placedMarkerLatLng, setPlacedMarkerLatLng] = useState<Coordinates | undefined>();
  const [textAddress, setTextAddress] = useState<string>();

  const [{ general }] = useContext(GlobalStateContext);

  useEffect(() => {
    setPlacedMarkerLatLng(initialPosition);
  }, []);

  const handleSelectLocationClicked = ({ lat, lng }: { lat: number; lng: number }) => {
    setPlacedMarkerLatLng({ latitude: lat, longitude: lng });
    setTextAddress("");
  };

  const handleSelectAddressTyped = async (address: string) => {
    setTextAddress(address);
    const latLangOfAddress = await geocodeByAddress(address);
    setPlacedMarkerLatLng({
      longitude: latLangOfAddress[0].geometry.location.lng(),
      latitude: latLangOfAddress[0].geometry.location.lat(),
    });
  };

  return (
    <Layout style={screenStyles.rowColumn}>
      <Layout style={screenStyles.sideOrTopSectionContainer}>
        <ScrollView style={{ width: "100%" }}>
          <Layout style={styles.header}>
            <Text>Search for an address or click on the map to select a location</Text>
            <Layout style={styles.headerSearchRow}>
              <LocationAutoComplete
                onSelect={handleSelectAddressTyped}
                value={textAddress}
                onChange={setTextAddress}
              />
              <Button
                disabled={!(placedMarkerLatLng?.longitude && placedMarkerLatLng?.latitude)}
                onPress={() => onSubmit(placedMarkerLatLng)}
                style={styles.button}
              >
                Save
              </Button>
            </Layout>
          </Layout>
        </ScrollView>
      </Layout>

      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          center={{
            lat:
              placedMarkerLatLng?.latitude ||
              general?.currentLocation?.centreCoordinates?.latitude ||
              PENANG_COORDINATES.latitude,
            lng:
              placedMarkerLatLng?.longitude ||
              general?.currentLocation?.centreCoordinates?.longitude ||
              PENANG_COORDINATES.longitude,
          }}
          defaultZoom={11}
          bootstrapURLKeys={{ key: WEB_MAP_API_KEY }}
          options={{ fullscreenControl: false }}
          onClick={({ lat, lng }: { lat: number; lng: number }) =>
            handleSelectLocationClicked({ lat: lat, lng: lng })
          }
        >
          {placedMarkerLatLng?.longitude && placedMarkerLatLng?.latitude ? (
            <MapIcon
              // @ts-ignore - Ignores lat lng type error
              lng={placedMarkerLatLng?.longitude}
              lat={placedMarkerLatLng?.latitude}
              touchable={false}
              icon={logoImageUrlOrId ? null : icon || "marker"}
              logoImageUrlOrId={logoImageUrlOrId}
              custom={custom}
              size={Size.SMALL}
            />
          ) : null}
        </GoogleMapReact>
      </div>
    </Layout>
  );
};

const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    justifyContent: "center",
    margin: 20,
    width: "20%",
  },
  header: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: 10,
    textAlign: "center",
  },
  headerSearchRow: {
    alignItems: "center",
    display: "flex",
    flexDirection: !isMobileDevice() ? "row" : "column",
    justifyContent: "space-between",
    textAlign: "center",
  },
});
