// props should be type MaterialTopTabBarProps - unable to get working
import { Tab, TabBar, useTheme } from "@ui-kitten/components";
import * as React from "react";
import { FC } from "react";

import { ListIcon, MapIcon } from "@h2c/common/src/atoms";

export const ReportAndRequestTabBar: FC = ({ navigation, state }: any) => {
  const theme = useTheme();

  return (
    <TabBar
      selectedIndex={state.index}
      onSelect={(index) => navigation.navigate(state.routeNames[index])}
      indicatorStyle={{ height: 0 }}
      style={{ backgroundColor: theme["color-primary-700"] }}
    >
      {/*@ts-ignore*/}
      <Tab title={"List View"} icon={ListIcon} size={"small"} />
      {/*@ts-ignore*/}
      <Tab title={"Map View"} icon={MapIcon} size={"small"} />
      {/*@ts-ignore*/}
      {/*<Tab title={"Statistics"} icon={ReportIcon} size={"small"} />*/}
    </TabBar>
  );
};
