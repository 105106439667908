import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import { RouteProp, useIsFocused } from "@react-navigation/native";
import { Card, Layout, List, Text } from "@ui-kitten/components";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { ListRenderItem } from "react-native";

import { ButtonLoading, WebRefreshButton } from "@h2c/common/src/atoms";
import { isMobileDevice } from "@h2c/common/src/constants";
import { BUNDLED_AUDIO_LANGS, BUNDLED_TEXT_LANGS } from "@h2c/common/src/i18n";
import { Callout, PageWithNewsPreview } from "@h2c/common/src/molecules";
import { screenStyles } from "@h2c/common/src/styles";
import { FETCH_POLICY, fixNull, SORT_ORDER, types } from "@h2c/service";
import { PageWithLocationConnector } from "@h2c/service/graphql/custom-types";
import { sortById } from "@h2c/service/src/dataSorting";

import { TopBarDrawerScreens } from "../molecules/TopBarDrawerScreens";
import { LocationSelectWithModalContainer } from "../organisms";
import { RouteParams } from "../routes";
import { GlobalStateContext } from "../state";

type PagesScreenProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
  route: RouteProp<RouteParams, "INFORMATION_PAGES">;
};

export const PagesScreen: FC<PagesScreenProps> = ({ navigation }) => {
  const [{ general }] = useContext(GlobalStateContext);
  const { data, loading, refetch } = types.useListPagesByLocationQuery({
    ...FETCH_POLICY,
    variables: { locationID: general.currentLocation?.id || "" },
  });
  const [createPageErrors, setCreatePageErrors] = useState<string | null>(null);
  const [createDefaultPage, { loading: createPageLoading1 }] = types.useCreateDefaultPageMutation();
  const [
    createPageLocation,
    { loading: createPageLoading2 },
  ] = types.useCreatePageLocationMutation();

  // Auto refetch pages on page refocus
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused && !loading) {
      refetch();
    }
  }, [isFocused]);

  const pages = useMemo(
    () =>
      sortById(
        fixNull(data?.pageByLocation?.items).map((e) => ({
          ...e.page,
          pageToLocationConnectorID: e.id,
        })),
        SORT_ORDER.ASCENDING
      ),
    [data]
  );

  /**
   * Create a blank(ish) page and adds it to current location
   */
  const createNewPage = async () => {
    // Ensure inside location
    if (!general.currentLocation?.id) {
      return setCreatePageErrors("Must be inside a selected location to create new page");
    }

    // Create blank default page
    let pageID;
    try {
      const response = await createDefaultPage();
      pageID = response.data?.createPage?.id;
    } catch (e) {
      return setCreatePageErrors(`Errors creating page template — ${e.message}`);
    }
    // Ensure blank created properly
    if (!pageID) {
      return setCreatePageErrors("Unknown error. Could not create page template");
    }

    // Add page to current location
    try {
      await createPageLocation({ variables: { locationID: general.currentLocation.id, pageID } });
    } catch (e) {
      return setCreatePageErrors(`Errors linking page to correct location — ${e.message}`);
    }
    setCreatePageErrors(null);
    refetch();
  };

  const renderPage: ListRenderItem<PageWithLocationConnector> = ({ item: page }) => (
      <PageWithNewsPreview
        page={page}
        key={page.id}
        onPress={() => navigation.navigate("PAGE_EDIT_PREAMBLE", { page })}
        currentAudioLang={BUNDLED_AUDIO_LANGS.ROH}
        currentTextLang={BUNDLED_TEXT_LANGS.EN}
        unpublished={!page.published}
      />
  );

  // This funky shit is used so the whole page scrolls, not just the list
  const screenSections = [
    () => <LocationSelectWithModalContainer onChange={refetch} />,
    () => (
      <Card disabled style={{ marginTop: 10 }}>
        <Text category={"h6"}>
          Pages that appear in cities do not automatically appear in parent countries.
        </Text>
        <Text>
          This allows you to give more localised information and contact details but increases the
          likelihood that information in either country or city will end up out of date. So please
          be careful
        </Text>
      </Card>
    ),
    () => (
      <ButtonLoading
        loading={createPageLoading1 || createPageLoading2}
        onPress={createNewPage}
        style={{ marginTop: 16, width: "50%", alignSelf: "center" }}
      >
        Create New Page
      </ButtonLoading>
    ),
    () => (createPageErrors ? <Callout text={createPageErrors} /> : null),
    () => (
      <List
        data={pages}
        contentContainerStyle={{ padding: -8 }}
        onRefresh={() => refetch()}
        refreshing={loading}
        renderItem={renderPage}
        ListHeaderComponent={
          <Layout style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <Text category={isMobileDevice() ? "h5" : "h4"} style={{ textAlign: "center" }}>
              Pages
            </Text>
            <WebRefreshButton loading={loading} onPress={() => refetch()} />
          </Layout>
        }
        ListEmptyComponent={
          <Text category={"h6"} style={{ textAlign: "center" }}>
            No pages for locations — this should not happen.
            <br />
            Refresh page and contact support if this continues
          </Text>
        }
      />
    ),
  ];

  return (
    <>
      <TopBarDrawerScreens navigation={navigation} />
      <List
        data={screenSections}
        renderItem={({ item }) => item()}
        contentContainerStyle={screenStyles.webCentredColumn}
      />
    </>
  );
};
