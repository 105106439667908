import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useState } from "react";

import { types } from "@h2c/service";
import { RequestType } from "@h2c/service/graphql/types";

import { RouteParams } from "../../routes";
import { UpdateReportProps } from "../ReportUpdateVisuals";

type SupplyRequestUpdateContainerProps = {
  navigation: StackNavigationProp<RouteParams, "SUPPLY_REQUEST_UPDATE">;
  route: RouteProp<RouteParams, "SUPPLY_REQUEST_UPDATE">;
};

/**
 * Container for update screen of supply requests using HelpSitesEditVisuals
 * @param route
 * @param navigation
 * @constructor
 */
export const SupplyRequestUpdateContainer: FC<SupplyRequestUpdateContainerProps> = ({
  route,
  navigation,
}) => {
  const [updateReportMutation, { error }] = types.useUpdateRequestMutation();
  const [deleteRequestMutation] = types.useDeleteRequestMutation();
  const [requestToDelete, setRequestToDelete] = useState<string | null>(null);

  // const onUpdate = async ({
  //   location,
  //   status,
  //   id,
  //   additionalInfo,
  //   type,
  // }: UpdateReportProps<RequestType>) => {
  //   try {
  //     //todo(jack): Better error handling
  //     if (status && location && type && status) {
  //       await updateReportMutation({
  //         variables: {
  //           additionalDetailsText: additionalInfo || "",
  //           id: id,
  //           //TODO(Jack): Better typing here
  //           location: {
  //             longitude: location.longitude || 0,
  //             latitude: location.latitude || 0,
  //           },
  //           type: type,
  //           status: status,
  //         },
  //       });
  //     }
  //   } catch (e) {
  //     //
  //   }
  //
  //   if (!error) {
  //     navigation.navigate("CONFIRMATION", {
  //       confirmationText: "Your report has been updated",
  //       onContinue: () => navigation.navigate("SUPPLY_REQUEST_LIST"),
  //     });
  //   }
  // };

  // const onDelete = async () => {
  //   try {
  //     await deleteRequestMutation({
  //       variables: {
  //         id: requestToDelete,
  //       },
  //     });
  //     setRequestToDelete(null);
  //   } catch (e) {
  //     //
  //   }
  //   if (!error) {
  //     navigation.navigate("CONFIRMATION", {
  //       confirmationText: "The request has been deleted",
  //       onContinue: () => navigation.pop(2),
  //     });
  //   }
  // };

  return (
    // <ReportUpdateVisuals
    //   dataItem={route.params.report}
    //   onUpdate={onUpdate}
    //   error={error?.message}
    //   loading={loading}
    //   types={REQUEST_TYPE_WITH_DETAILS}
    //   setDataItemToDelete={(id: string | null) => setRequestToDelete(id)}
    //   dataItemToDelete={requestToDelete}
    //   deleting={deleteStatus.loading}
    //   errorDeleting={deleteStatus.error?.message}
    //   deleteDataItem={onDelete}
    // />
    <></>
  );
};
