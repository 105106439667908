import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useEffect, useState } from "react";

import { LoadingFullscreen } from "@h2c/common/src/molecules";
import { FETCH_POLICY, types } from "@h2c/service";
import { HelpSiteType } from "@h2c/service/graphql/types";
import {
  DataItemCategory,
  GenericDataItem,
  getUserFriendlyGraphqlErrorMessage,
  HELP_SITE_WITH_DETAILS,
  helpSiteForPage,
  UpdateHelpSiteProps,
  validateHelpSiteInfo,
} from "@h2c/service/src/dataItemManipulation";

import { RouteParams } from "../../routes";

import { HelpSitesEditVisuals } from "./HelpSitesEditVisuals";

type HelpSitesUpdateContainerProps = {
  navigation: StackNavigationProp<RouteParams, "HELP_SITE_UPDATE">;
  route: RouteProp<RouteParams, "HELP_SITE_UPDATE">;
};

/**
 * Container for update screen of Help sites using HelpSitesEditVisuals
 *
 * Can either be passed details as param or be passed an id from param and
 * fetch necessary details
 *
 * @param navigation
 * @param route
 * @constructor
 */
export const HelpSitesUpdateContainer: FC<HelpSitesUpdateContainerProps> = ({
  navigation,
  route,
}) => {
  const pageLogoUrlOrId =
    (route.params.type === "fetchById" && route.params.pageLogoUrlOrId) || undefined;
  const [helpSite, setHelpSite] = useState<GenericDataItem<HelpSiteType> | null>(null);

  // Fetch only used if full HelpSite not provided
  const [
    fetchHelpSite,
    { data, loading: fetchLoading, error: fetchError },
  ] = types.useGetHelpSiteLazyQuery(FETCH_POLICY);
  // Set helpsite using param or fetch
  useEffect(() => {
    if (route.params.type === "withDetails") {
      setHelpSite(route.params.report);
    } else if (route.params.type === "fetchById") {
      fetchHelpSite({ variables: { helpSiteID: route.params.helpSiteId } });
    }
  }, []);

  // Update state or exit flow based on lazy fetch
  useEffect(() => {
    if (fetchError) {
      return navigation.pop(1);
    }
    const fetchedSite = data?.getHelpSite;
    if (fetchedSite) {
      setHelpSite({
        id: fetchedSite.id,
        category: DataItemCategory.HELP_SITE,
        type: HelpSiteType.OrgLocation,
        createdAt: fetchedSite.createdAt,
        title: fetchedSite.title.source,
        phoneNumber: fetchedSite.phoneNumber,
        location: fetchedSite.coordinates,
        locationID: fetchedSite.locationID,
      });
    }
  }, [data, fetchError]);

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [
    updateHelpSiteMutation,
    { error: updateError, loading },
  ] = types.useUpdateHelpSiteMutation();

  const [deleteHelpSiteMutation, deleteHelpSiteStatus] = types.useDeleteHelpSiteMutation();
  const [helpSiteToDelete, setHelpSiteToDelete] = useState<string | null>(null);

  // Validate then update in graphql and call callback if editing page
  const onUpdate = async ({
    location,
    id,
    additionalInfo,
    type,
    emailAddress,
    organisationName,
    phoneNumber,
    title,
  }: UpdateHelpSiteProps<HelpSiteType>) => {
    if (helpSite) {
      let helpSiteResponse: types.UpdateHelpSiteMutation["updateHelpSite"];
      const errors = validateHelpSiteInfo({
        title,
        phoneNumber,
        emailAddress,
        location,
        type,
        locationID: helpSite.locationID,
      });
      //TODO(JACK): Figure out why typescript requires this
      if (errors.length == 0 && id && location && type && title) {
        try {
          const response = await updateHelpSiteMutation({
            variables: {
              additionalInformation: {
                source: additionalInfo || "",
                translationIndex: 1,
              },
              id: id,
              //TODO(Jack): Better typing here
              location: {
                longitude: location.longitude || 0,
                latitude: location.latitude || 0,
              },
              type: type,
              emailAddress: emailAddress === "" ? null : emailAddress,
              organisationName: organisationName,
              phoneNumber: phoneNumber === "" ? null : phoneNumber,
              title: { source: title, translationIndex: 0 },
              pageLogoUrlOrId,
            },
          });

          helpSiteResponse = response.data?.updateHelpSite;
        } catch (e) {
          errors.push(getUserFriendlyGraphqlErrorMessage(e.message));
        }

        if (!helpSiteResponse)
          errors.push(
            "Unknown error but no help site responded. Check map to see if has worked and try again if it has not."
          );

        if (errors.length == 0) {
          navigation.navigate("CONFIRMATION", {
            confirmationText: "The help site has been updated",
            onContinue: () =>
              route.params.type === "fetchById" && helpSiteResponse
                ? route.params.onSave({
                    type: "helpSite",
                    helpSiteID: helpSiteResponse.id,
                    coordinates: helpSiteResponse.coordinates,
                    text: helpSiteResponse.title,
                    phoneNumber: helpSiteResponse.phoneNumber || undefined,
                  })
                : navigation.pop(2),
          });
        }
      }
      setValidationErrors(errors);
    }
  };

  const onDelete = async () => {
    try {
      await deleteHelpSiteMutation({
        variables: {
          id: helpSiteToDelete,
        },
      });
      setHelpSiteToDelete(null);
    } catch (e) {
      //
    }
    if (!updateError) {
      navigation.navigate("CONFIRMATION", {
        confirmationText: "The help site has been deleted",
        onContinue: () =>
          route.params.type === "fetchById" ? route.params.onDelete() : navigation.pop(2),
      });
    }
  };

  if (!helpSite || fetchLoading) return <LoadingFullscreen />;

  return (
    <HelpSitesEditVisuals
      dataItem={helpSite}
      onUpdate={onUpdate}
      error={validationErrors.join(", ")}
      loading={loading}
      setDataItemToDelete={(id: string | null) => setHelpSiteToDelete(id)}
      dataItemToDelete={helpSiteToDelete}
      deleting={deleteHelpSiteStatus.loading}
      errorDeleting={deleteHelpSiteStatus.error?.message}
      deleteDataItem={onDelete}
      navigation={navigation}
      types={HELP_SITE_WITH_DETAILS.concat(pageLogoUrlOrId ? helpSiteForPage(pageLogoUrlOrId) : [])}
      pageLogoUrlOrId={pageLogoUrlOrId}
    />
  );
};
