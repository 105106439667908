import { DrawerNavigationProp } from "@react-navigation/drawer/lib/typescript/src/types";
import { RouteProp, useIsFocused } from "@react-navigation/native";
import React, { FC, useContext, useEffect, useState } from "react";

import { PENANG_COORDINATES } from "@h2c/common/src/constants";
import { MapVisuals } from "@h2c/common/src/organisms/MapVisuals";
import { types } from "@h2c/service";
import { HelpSiteType } from "@h2c/service/graphql/types";
import {
  formatHelpSites,
  GenericDataItem,
  HELP_SITE_WITH_DETAILS,
  helpSiteForPage,
} from "@h2c/service/src/dataItemManipulation";

import { TopBarDrawerScreens } from "../../molecules/TopBarDrawerScreens";
import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";

type HelpSiteMapContainerProps = {
  navigation: DrawerNavigationProp<RouteParams, "MAIN_DRAWER_ITEMS">;
  route: RouteProp<RouteParams, "HELP_SITES">;
};

/**
 * Container for map view of supply HelpSites using MapVisuals
 * @param navigation
 * @constructor
 */
export const HelpSiteMapContainer: FC<HelpSiteMapContainerProps> = ({ navigation }) => {
  const [{ general }] = useContext(GlobalStateContext);
  const { data, loading, refetch } = types.useListHelpSitesByLocationQuery({
    fetchPolicy: "cache-and-network",
    variables: { locationID: general.currentLocation?.id || "" },
  });

  // Auto refetch help sites on page refocus
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused && !loading) {
      refetch();
      hideHelpSite();
    }
  }, [isFocused]);

  const [selectedHelpSite, setSelectedHelpSite] = useState<GenericDataItem<HelpSiteType>>();

  const showHelpSite = (r?: GenericDataItem<HelpSiteType>) => {
    setSelectedHelpSite(r);
  };

  const hideHelpSite = () => {
    setSelectedHelpSite(undefined);
  };

  const refreshData = () => {
    setSelectedHelpSite(undefined);
    refetch();
  };

  return (
    <>
      <TopBarDrawerScreens navigation={navigation} />
      <MapVisuals
        onUpdate={(helpSite: GenericDataItem<HelpSiteType>) =>
          navigation.navigate("HELP_SITE_UPDATE", { type: "withDetails", report: helpSite })
        }
        loading={loading}
        refetch={refreshData}
        data={formatHelpSites(data)}
        show={showHelpSite}
        title={"Help Sites"}
        defaultCenter={{
          latitude:
            general.currentLocation?.centreCoordinates?.latitude || PENANG_COORDINATES.latitude,
          longitude:
            general.currentLocation?.centreCoordinates?.longitude || PENANG_COORDINATES.longitude,
        }}
        hide={hideHelpSite}
        selected={selectedHelpSite}
        iconInfo={(HelpSite: GenericDataItem<HelpSiteType>) =>
          HELP_SITE_WITH_DETAILS.concat(
            HelpSite.pageLogoUrlOrId ? helpSiteForPage(HelpSite.pageLogoUrlOrId) : []
          ).find((t) => t.type === HelpSite.type)
        }
        onCreate={() => navigation.navigate("HELP_SITE_CREATE")}
        LocationSelectWithModal={() => <LocationSelectWithModalContainer onChange={refetch} />}
      />
    </>
  );
};
