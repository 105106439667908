import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useState } from "react";

import { types } from "@h2c/service";
import { IncidentType } from "@h2c/service/graphql/types";
import { INCIDENT_TYPE_WITH_DETAILS } from "@h2c/service/src/dataItemManipulation";

import { RouteParams } from "../../routes";
import { UpdateReportProps, ReportUpdateVisuals } from "../ReportUpdateVisuals";

type IncidentUpdateContainerProps = {
  navigation: StackNavigationProp<RouteParams, "INCIDENT_UPDATE">;
  route: RouteProp<RouteParams, "INCIDENT_UPDATE">;
};

/**
 * Container for update screen of Covid reports using HelpSitesEditVisuals
 * @param navigation
 * @param route
 * @constructor
 */
export const IncidentsUpdateContainer: FC<IncidentUpdateContainerProps> = ({
  navigation,
  route,
}) => {
  const [updateReportMutation, { error, loading }] = types.useUpdateIncidentMutation();

  const [deleteReportMutation, deleteStatus] = types.useDeleteIncidentMutation();
  const [reportToDelete, setReportToDelete] = useState<string | null>(null);

  const onUpdate = async ({
    location,
    status,
    id,
    additionalInfo,
    type,
  }: UpdateReportProps<IncidentType>) => {
    try {
      //todo(jack): Better error handling
      if (status && location && type && status) {
        await updateReportMutation({
          variables: {
            additionalDetailsText: additionalInfo || "",
            id: id,
            //TODO(Jack): Better typing here
            location: {
              longitude: location.longitude || 0,
              latitude: location.latitude || 0,
            },
            type: type,
            status: status,
          },
        });
      }
    } catch (e) {
      //
    }

    if (!error) {
      navigation.navigate("CONFIRMATION", {
        confirmationText: "Your report has been updated",
        onContinue: () => navigation.pop(2),
      });
    }
  };

  const onDelete = async () => {
    try {
      await deleteReportMutation({
        variables: {
          id: reportToDelete,
        },
      });
      setReportToDelete(null);
    } catch (e) {
      //
    }
    if (!error) {
      navigation.navigate("CONFIRMATION", {
        confirmationText: "The report has been deleted",
        onContinue: () => navigation.pop(2),
      });
    }
  };

  return (
    <ReportUpdateVisuals
      dataItem={route.params.report}
      onUpdate={onUpdate}
      error={error?.message}
      loading={loading}
      types={INCIDENT_TYPE_WITH_DETAILS}
      setDataItemToDelete={(id: string | null) => setReportToDelete(id)}
      dataItemToDelete={reportToDelete}
      deleting={deleteStatus.loading}
      errorDeleting={deleteStatus.error?.message}
      deleteDataItem={onDelete}
      navigation={navigation}
    />
  );
};
