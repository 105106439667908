import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useContext, useState } from "react";

import { PENANG_COORDINATES } from "@h2c/common/src/constants";
import { MapVisuals } from "@h2c/common/src/organisms/MapVisuals";
import { types } from "@h2c/service";
import { RequestType } from "@h2c/service/graphql/types";
import {
  formatSupplyRequests,
  GenericDataItem,
  REQUEST_TYPE_WITH_DETAILS,
} from "@h2c/service/src/dataItemManipulation";
import { createdAtLessThanNWeeksOld } from "@h2c/service/src/util";

import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";

type ReportMapContainerProps = {
  navigation: StackNavigationProp<RouteParams, "COVID_REPORT_STATISTICS">;
};

/**
 * Container for map view of supply requests using MapVisuals
 * @param navigation
 * @constructor
 */
export const SupplyRequestMapContainer: FC<ReportMapContainerProps> = ({ navigation }) => {
  const [{ general }] = useContext(GlobalStateContext);
  const { data, loading, refetch } = types.useListSupplyRequestsByLocationQuery({
    fetchPolicy: "cache-and-network",
    variables: { locationID: general.currentLocation?.id || "" },
  });

  const [selectedRequest, setSelectedRequest] = useState<GenericDataItem<RequestType>>();

  const showRequest = (r?: GenericDataItem<RequestType>) => {
    setSelectedRequest(r);
  };

  const hideRequest = () => {
    setSelectedRequest(undefined);
  };

  const refreshData = () => {
    setSelectedRequest(undefined);
    refetch();
  };

  const NUMBER_OF_WEEKS_TO_SHOW = 2;
  return (
    <MapVisuals
      onUpdate={(report: GenericDataItem<RequestType>) =>
        navigation.navigate("SUPPLY_REQUEST_UPDATE", { report: report })
      }
      loading={loading}
      refetch={refreshData}
      data={formatSupplyRequests(data).filter(createdAtLessThanNWeeksOld(NUMBER_OF_WEEKS_TO_SHOW))}
      show={showRequest}
      defaultCenter={{
        latitude:
          general.currentLocation?.centreCoordinates?.latitude || PENANG_COORDINATES.latitude,
        longitude:
          general.currentLocation?.centreCoordinates?.longitude || PENANG_COORDINATES.longitude,
      }}
      title={"Supply Requests"}
      hide={hideRequest}
      selected={selectedRequest}
      iconInfo={(request: GenericDataItem<RequestType>) =>
        REQUEST_TYPE_WITH_DETAILS.find((t) => t.type === request.type)
      }
      LocationSelectWithModal={() => <LocationSelectWithModalContainer onChange={refetch} />}
      numberOfWeeksShown={NUMBER_OF_WEEKS_TO_SHOW}
    />
  );
};
