import { Button, Card, Icon, Layout, Spinner, Text } from "@ui-kitten/components";
import React, { FC, useEffect } from "react";
import { StyleSheet, View } from "react-native";

import { AudioPlayer, FILE_TYPES, FilePicker } from "@h2c/common/src/molecules";
import { AnnouncementTextTranslationPreview } from "@h2c/common/src/molecules/AnnouncementTextTranslationPreview";
import { Callout } from "@h2c/common/src/molecules/Callout";
import { screenStyles } from "@h2c/common/src/styles";

type AddAudioTranslationVisualProps = {
  upload: () => Promise<void>;
  setSelectedRecordingUri: (pathToImageFile?: string) => void;
  error?: string;
  selectedRecordingUri: string | null;
  currentTranslationAudio?: string;
  languageName?: string;
  uploading: boolean;
  sourceTitle: string;
  sourceBody: string;
};

export const AddAudioTranslationVisuals: FC<AddAudioTranslationVisualProps> = ({
  upload,
  error,
  setSelectedRecordingUri,
  selectedRecordingUri,
  currentTranslationAudio,
  languageName,
  uploading,
  sourceTitle,
  sourceBody,
}) => {
  useEffect(() => {
    setSelectedRecordingUri(currentTranslationAudio);
  }, []);

  {
    /*TODO(Jack): Fix permissions errors for recording on web*/
  }
  const renderAudioSelectors = () => {
    return (
      <>
        <Layout style={styles.spacedButtons}>
          <FilePicker
            onGetLocalUriToFile={setSelectedRecordingUri}
            fileType={FILE_TYPES.AUDIO}
            buttonText={"Pick an audio clip"}
          />
        </Layout>
        {/*<Layout style={styles.spacedButtons}>*/}
        {/*  <AudioRecorder storeRecordingUri={setSelectedRecordingUri} />*/}
        {/*</Layout>*/}
      </>
    );
  };

  if (uploading) {
    return (
      <View style={screenStyles.centeredInContainer}>
        <Spinner status="primary" />
      </View>
    );
  } else {
    return (
      <Layout style={screenStyles.webCentredColumn}>
        <View style={screenStyles.screenMainSectionSoButtonsAtBottom}>
          <Card style={screenStyles.verticalMargin} disabled>
            <Text>
              Choose an audio file for your {languageName || ""} translation. This will appear next
              to the announcement to translate both the header and body.
            </Text>
          </Card>
          <Card disabled>
            <AnnouncementTextTranslationPreview
              languageName={"English"}
              title={sourceTitle}
              body={sourceBody}
            />
          </Card>

          <Layout style={screenStyles.centeredInContainer}>
            {selectedRecordingUri ? (
              <Layout style={styles.rowOfButtons}>
                <AudioPlayer url={selectedRecordingUri} />
                <Button
                  status={"danger"}
                  style={styles.deleteButton}
                  onPress={() => setSelectedRecordingUri(undefined)}
                  accessoryRight={() => <Icon style={styles.icon} name={"trash"} />}
                />
              </Layout>
            ) : (
              renderAudioSelectors()
            )}
          </Layout>
        </View>
        {error ? (
          <Callout status={"danger"} icon={{ name: "exclamation-circle" }} text={error} />
        ) : null}
        <Button disabled={!selectedRecordingUri} style={screenStyles.bottomButton} onPress={upload}>
          Save Translation
        </Button>
      </Layout>
    );
  }
};

export const styles = StyleSheet.create({
  deleteButton: {
    marginHorizontal: 10,
  },
  icon: { color: "#FFF" },
  rowOfButtons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  spacedButtons: { padding: 50 },
});
