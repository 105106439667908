import { LangsWithDetails } from "@h2c/common/src/i18n";
import { TranslatableLanguage } from "@h2c/common/src/organisms/ChooseTranslationVisuals";
import { Edit } from "@h2c/common/src/page-parser/edit";
import {
  Block,
  HelpSiteBlock,
  PageBlock,
  TranslateablePage,
  Translations,
} from "@h2c/common/src/page-parser/types";
import { PageWithLocationConnector } from "@h2c/service/graphql/custom-types";
import {
  Page,
  Maybe,
  HelpSiteType,
  IncidentType,
  CovidReportType,
  RequestType,
} from "@h2c/service/graphql/types";
import { GenericDataItem, Coordinates } from "@h2c/service/src/dataItemManipulation";

import { UserType } from "../types/cognitoidentityserviceprovider";

export enum TRANSLATION_TYPE {
  AUDIO,
  TEXT,
}

/**
 * The param types for the various routes.
 *
 * See: https://reactnavigation.org/docs/typescript/
 */
export type RouteParams = {
  //Help to connect
  EDIT_CONTENT: undefined;
  MAIN_TABS: undefined;
  MAIN_DRAWER_ITEMS: undefined;
  ADMIN_SETTINGS: undefined;
  SETTINGS_ADD_LOCATION: undefined;
  SETTINGS_ADD_ADMIN: { isSuperAdmin: boolean };
  SETTINGS_UPDATE_ADMIN: { admin: UserType };
  SETTINGS_UPDATE_EMERGENCY_CONTACTS: undefined;
  CONFIRMATION: {
    confirmationText: string;
    onContinue: () => void;
  };
  CHOOSE_TRANSLATION: {
    title: string;
    translationType: TRANSLATION_TYPE;
  };

  INFORMATION_PAGES: undefined;
  PAGE_EDIT: { page: Page };
  PAGE_BLOCK_EDIT: {
    block: Maybe<Block>;
    onSave: <T extends Block>(newBlock: T) => void;
    onHelpSite: () => void;
  };
  PAGE_SAVE: { page: Required<TranslateablePage>; edits: Edit<any>[] };
  PAGE_VIEW: { type: "serialized"; page: Page } | { type: "deserialized"; block: PageBlock };
  PAGE_EDIT_PREAMBLE: { page: PageWithLocationConnector };

  /// Allows the user to translate pages.
  /// page: The page to translate.
  /// onSave: An optional save function. If not supplied, a default will be used.
  PAGE_TRANSLATE: { page: Required<TranslateablePage>; onSave?: (page: Page) => Promise<void> };
  PAGE_TRANSLATE_TEXT: undefined;
  PAGE_TRANSLATE_AUDIO: undefined;
  PAGE_TRANSLATE_TEXT_MANAGE: {
    langName: string;
    translations?: Translations<string>[0]; // we fake index into the type to get the child
    onSave: (updates: { [key: number]: string }) => void;
    source: { [key: number]: string };
  };
  PAGE_TRANSLATE_AUDIO_MANAGE: {
    langName: string;
    translations?: Translations<string>[0]; // we fake index into the type to get the child
    onSave: (updates: { [key: number]: string }) => void;
    source: { [key: number]: string };
  };
  LOCATION_CHOOSE: {
    initialPosition?: Coordinates;
    onSubmit: (location?: Coordinates) => void;
    icon: string;
    custom: boolean;
    logoImageUrlOrId?: string;
  };

  HELP_SITES: undefined;
  HELP_SITE_UPDATE:
    | { type: "withDetails"; report: GenericDataItem<HelpSiteType> }
    | {
        type: "fetchById";
        helpSiteId: string;
        pageLogoUrlOrId: string;
        onSave: (helpSite: HelpSiteBlock) => void;
        onDelete: () => void;
      };
  HELP_SITE_CREATE:
    | undefined
    | { pageLogoUrlOrId: string; onSave: (helpSite: HelpSiteBlock) => void };

  LANGUAGES_HOME: undefined;
  LANGUAGE_EDIT: {
    language: TranslatableLanguage;
    updateAudio?: boolean;
  };
  LANGUAGE_TUTORIAL_VOICE_OVER: {
    langID: string;
  };

  LANGUAGE_CREATE_TEXT: undefined;
  LANGUAGE_CREATE_AUDIO: undefined;

  TUTORIAL_VIDEO: undefined;

  ABOUT: undefined;

  INCIDENTS: undefined;
  INCIDENTS_MAP: { title: string };
  INCIDENTS_LIST: undefined;
  INCIDENT_UPDATE: { report: GenericDataItem<IncidentType> };

  //COVID 19 Routes - No longer required, kept in for typing
  REQUESTS: undefined;
  REPORTS: undefined;

  ANNOUNCE: undefined;
  CREATE_ANNOUNCEMENT: undefined;

  UPLOAD_ANNOUNCEMENT: undefined;
  MANAGE_TRANSLATIONS: {
    text: LangsWithDetails[];
    audio: LangsWithDetails[];
  };

  ANNOUNCEMENT_ADD_TEXT_TRANSLATION: {
    title: string;
    translation?: { title: string; body: string };
    language: TranslatableLanguage;
  };
  ANNOUNCEMENT_ADD_AUDIO_TRANSLATION: { title: string; language: TranslatableLanguage };
  ANNOUNCEMENT_TRANSLATIONS: undefined;
  ANNOUNCEMENT_SELECT_LOCATION: undefined;

  COVID_REPORT_STATISTICS: { title: string };
  COVID_REPORT_MAP: { title: string };
  COVID_REPORT_LIST: undefined;

  SUPPLY_REQUEST_STATISTICS: { title: string };
  SUPPLY_REQUEST_MAP: { title: string };
  SUPPLY_REQUEST_LIST: undefined;

  COVID_REPORT_UPDATE: { report: GenericDataItem<CovidReportType> };
  SUPPLY_REQUEST_UPDATE: { report: GenericDataItem<RequestType> };
};
