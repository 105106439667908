import { Layout, Text } from "@ui-kitten/components";
import React, { FC } from "react";
import { View } from "react-native";

import { screenStyles } from "@h2c/common/src";
import { isMobileDevice } from "@h2c/common/src/constants";

const VIDEO_WIDTH = isMobileDevice() ? 300 : 560;

const ASPECT_RATIO = 1 / (16 / 9);

export const TutorialVideoScreen: FC = () => {
  return (
    <Layout style={screenStyles.webCentredColumn}>
      <Layout style={screenStyles.centeredInContainer}>
        <View style={{ height: VIDEO_WIDTH * ASPECT_RATIO, width: VIDEO_WIDTH }}>
          <Text category={"h2"}>COMING SOON</Text>
        </View>
        {/*<Video*/}
        {/*  source={{*/}
        {/*    uri:*/}
        {/*      "https://refugee-covid-custom-uploads.s3-eu-west-1.amazonaws.com/tutorial-english-audio.mp4",*/}
        {/*  }}*/}
        {/*  isMuted={false}*/}
        {/*  resizeMode={Video.RESIZE_MODE_STRETCH}*/}
        {/*  shouldPlay*/}
        {/*  style={{ height: VIDEO_WIDTH * ASPECT_RATIO, width: VIDEO_WIDTH }}*/}
        {/*  useNativeControls*/}
        {/*/>*/}
      </Layout>
    </Layout>
  );
};
