import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Icon,
  TopNavigation,
  TopNavigationAction,
  Text,
  Button,
  Spinner,
  Card,
  ButtonGroup,
} from "@ui-kitten/components";
import React, { FC } from "react";
import { Platform, View } from "react-native";

import { screenStyles } from "@h2c/common/src";
import { isMobileDevice } from "@h2c/common/src/constants";
import { Callout } from "@h2c/common/src/molecules/Callout";
import { Edit, getChanges } from "@h2c/common/src/page-parser/edit";
import { serialize } from "@h2c/common/src/page-parser/serialize";
import { PageBlock } from "@h2c/common/src/page-parser/types";
import { types } from "@h2c/service";
import { Page } from "@h2c/service/graphql/types";

import { RouteParams } from "../routes";

type SavePageScreenProps = {
  navigation: StackNavigationProp<RouteParams, "PAGE_SAVE">;
  route: RouteProp<RouteParams, "PAGE_SAVE">;
};

export const PageSaveScreen: FC<SavePageScreenProps> = ({ navigation, route }) => {
  const {
    page: { page, text, meta, audio },
    edits,
  } = route.params;

  const edited = edits.length > 1; // the first edit is always 'replace'

  const [
    savePage,
    { error: pageUpdateError, loading: pageUpdateLoading },
  ] = types.useUpdatePageMutation();

  const savePageUpdates = async (serializedPage: Page) => {
    // these are invalid fields on the input object
    delete serializedPage.updatedAt;
    delete serializedPage.createdAt;

    if (edited) {
      for (const textTranslation of serializedPage.text) {
        textTranslation.outOfDate = true;
      }

      for (const audioTranslation of serializedPage.audio) {
        audioTranslation.outOfDate = true;
      }
    }

    // eslint-disable-next-line no-useless-catch
    try {
      // we can re-run this because it is idempotent
      const { data } = await savePage({
        variables: {
          page: serializedPage,
        },
      });

      // this should never trigger, but is here for safety
      if (data === undefined || data.updatePage === null || data.updatePage === undefined) {
        console.error("Saving page returned no id.");
        return;
      }
    } catch (e) {
      // todo(arlyon): better way to handle this?
      throw e;
    }

    navigation.navigate("CONFIRMATION", {
      confirmationText: "Your page has been published to all users in the selected locations.",
      onContinue: () => navigation.navigate("INFORMATION_PAGES"),
    });
  };

  const error = pageUpdateError;
  const loading = pageUpdateLoading;

  return (
    <View style={{ height: Platform.OS === "web" ? "100vh" : "100%" }}>
      <TopNavigation
        title="Save Page"
        accessoryLeft={(props) => (
          <TopNavigationAction
            {...props}
            icon={(props) => <Icon {...props} name="arrow-left" />}
            onPress={() => navigation.pop()}
          />
        )}
      />
      <View style={screenStyles.webCentredColumn}>
        {edits.length < 2 && (
          <Callout status="info" text="You haven't made any changes to this page." />
        )}
        {/*TODO(ssp6): Removed because buggy, card #26*/}
        {/*<EditVisualiser page={page} edits={edits} />*/}
        <Button
          appearance="outline"
          style={{ marginTop: 16 }}
          onPress={() =>
            navigation.navigate("PAGE_VIEW", {
              type: "deserialized",
              block: page,
            })
          }
        >
          Preview Changes
        </Button>
        <ButtonGroup style={{ width: "100%" }}>
          <Button
            style={{ marginTop: 16 }}
            appearance="outline"
            disabled={loading}
            onPress={() =>
              navigation.navigate("PAGE_TRANSLATE", {
                page: { page, text, meta, audio },
                onSave: savePageUpdates,
              })
            }
          >
            Translate Then Publish
          </Button>
          <Button
            style={{ marginTop: 16 }}
            disabled={loading}
            onPress={() => savePageUpdates(serialize({ page, text, meta, audio }))}
            accessoryRight={loading ? () => <Spinner status="primary" /> : undefined}
          >
            Publish Now
          </Button>
        </ButtonGroup>
        {error && <Callout text={error?.message} status="danger" />}
      </View>
    </View>
  );
};

/**
 * Takes a list of edits and visualises them.
 */
const EditVisualiser: FC<{ page: PageBlock; edits: Edit<any>[] }> = ({ page, edits }) => {
  const { deletedElements, newElements } = getChanges(page, edits);
  return deletedElements.length > 0 || newElements.length > 0 ? (
    <Card style={{ marginTop: 16 }}>
      <Text category={isMobileDevice() ? "h5" : "h4"}>Sections Created & Deleted</Text>
      {newElements.length ? (
        <View>
          <Text category={isMobileDevice() ? "h6" : "h5"}>Created</Text>
          {newElements.map((item, index) => (
            <Text key={index}>Created a {item.type}.</Text>
          ))}
        </View>
      ) : null}
      {deletedElements.length ? (
        <View>
          <Text category={isMobileDevice() ? "h6" : "h5"}>Deleted</Text>
          {deletedElements.map((item, index) => (
            <Text key={index}>Deleted a {item.type}.</Text>
          ))}
        </View>
      ) : null}
      {/* todo(arlyon) This is probably just unneeded data... */}
      {/*{movedElements.length ? (*/}
      {/*  <View>*/}
      {/*    <Text category="h4">Moved</Text>*/}
      {/*    {movedElements.map(({ start, end }, index) => (*/}
      {/*      <Text key={index}>*/}
      {/*        Moved {editedPage.elements[end].type} from position {start + 1} to position {end + 1}.*/}
      {/*      </Text>*/}
      {/*    ))}*/}
      {/*  </View>*/}
      {/*) : null}*/}
    </Card>
  ) : null;
};
