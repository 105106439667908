import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useContext, useState } from "react";

import { types } from "@h2c/service";
import { HelpSiteType } from "@h2c/service/graphql/types";
import {
  DataItemCategory,
  getUserFriendlyGraphqlErrorMessage,
  HELP_SITE_WITH_DETAILS,
  helpSiteForPage,
  UpdateHelpSiteProps,
  validateHelpSiteInfo,
} from "@h2c/service/src/dataItemManipulation";

import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";

import { HelpSitesEditVisuals } from "./HelpSitesEditVisuals";

type HelpSitesCreateContainerProps = {
  navigation: StackNavigationProp<RouteParams, "HELP_SITE_CREATE">;
  route: RouteProp<RouteParams, "HELP_SITE_CREATE">;
};

/**
 * Container for update screen of Help sites using HelpSitesEditVisuals
 */
export const HelpSitesCreateContainer: FC<HelpSitesCreateContainerProps> = ({
  navigation,
  route,
}) => {
  const onSave = route.params?.onSave;
  const pageLogoUrlOrId = route.params?.pageLogoUrlOrId;

  const [{ general }] = useContext(GlobalStateContext);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [createHelpSiteMutation, { loading }] = types.useCreateHelpSiteMutation();

  const onCreate = async ({
    location,
    additionalInfo,
    type,
    emailAddress,
    organisationName,
    phoneNumber,
    title,
    pageLogoUrlOrId,
  }: UpdateHelpSiteProps<HelpSiteType>) => {
    let helpSiteResponse: types.CreateHelpSiteMutation["createHelpSite"];
    const errors = validateHelpSiteInfo({
      title,
      phoneNumber,
      emailAddress,
      location,
      type,
      locationID: general.currentLocation?.id,
      pageLogoUrlOrId,
    });

    if (errors.length == 0) {
      try {
        //todo(jack): Better error handling
        if (type && location && title && general.currentLocation?.id) {
          const response = await createHelpSiteMutation({
            variables: {
              additionalInformation: {
                source: additionalInfo || "",
                translationIndex: 1,
              },
              //TODO(Jack): Better typing here
              location: {
                longitude: location.longitude || 0,
                latitude: location.latitude || 0,
              },
              type: type,
              emailAddress: emailAddress === "" ? null : emailAddress,
              organisationName: organisationName,
              phoneNumber: phoneNumber === "" ? null : phoneNumber,
              title: { source: title, translationIndex: 0 },
              locationID: general.currentLocation.id,
              pageLogoUrlOrId: pageLogoUrlOrId === null ? undefined : pageLogoUrlOrId,
            },
          });

          helpSiteResponse = response.data?.createHelpSite;
        }
      } catch (e) {
        errors.push(getUserFriendlyGraphqlErrorMessage(e.message));
      }

      if (!helpSiteResponse)
        errors.push(
          "Unknown error but no help site responded. Check map to see if has worked and try again if it has not."
        );

      if (errors.length === 0) {
        navigation.navigate("CONFIRMATION", {
          confirmationText: "The help site has been created",
          onContinue: () =>
            onSave && helpSiteResponse
              ? onSave({
                  type: "helpSite",
                  helpSiteID: helpSiteResponse.id,
                  coordinates: helpSiteResponse.coordinates,
                  text: helpSiteResponse.title,
                  phoneNumber: helpSiteResponse.phoneNumber || undefined,
                })
              : navigation.pop(2),
        });
      }
    }

    setValidationErrors(errors);
  };

  return (
    <HelpSitesEditVisuals
      LocationSelectWithModal={
        pageLogoUrlOrId ? undefined : () => <LocationSelectWithModalContainer />
      }
      dataItem={{
        id: "",
        createdAt: "",
        category: DataItemCategory.HELP_SITE,
        location: { longitude: 0, latitude: 0 },
        type: pageLogoUrlOrId ? HelpSiteType.OrgLocation : HelpSiteType.ClinicCheap,
      }}
      onUpdate={onCreate}
      error={validationErrors.join(", ")}
      loading={loading}
      navigation={navigation}
      types={HELP_SITE_WITH_DETAILS.concat(pageLogoUrlOrId ? helpSiteForPage(pageLogoUrlOrId) : [])}
      pageLogoUrlOrId={pageLogoUrlOrId}
    />
  );
};
