import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { StackScreenProps } from "@react-navigation/stack";
import { Icon, TopNavigation, TopNavigationAction, useTheme, Spinner } from "@ui-kitten/components";
import React, { FC, useContext, useState } from "react";
import { Platform, View } from "react-native";

import { LanguageContext, LangsWithDetails, BUNDLED_TEXT_LANGS } from "@h2c/common/src/i18n";
import {
  ChooseTranslationVisuals,
  TranslationState,
} from "@h2c/common/src/organisms/ChooseTranslationVisuals";
import { serialize } from "@h2c/common/src/page-parser/serialize";
import {
  Translations,
  TranslateableText,
  Block,
} from "@h2c/common/src/page-parser/types";

import { RouteParams } from "../routes";

const { Navigator, Screen } = createMaterialTopTabNavigator<RouteParams>();

type PageTranslateScreenProps = StackScreenProps<RouteParams, "PAGE_TRANSLATE">;

export const PageTranslateScreen: FC<PageTranslateScreenProps> = ({ navigation, route }) => {
  const {
    page: { page, text, meta, audio },
    onSave,
  } = route.params;

  const [translatedText, setTranslatedText] = useState(text);
  const [translatedAudio, setTranslatedAudio] = useState(audio);
  const [saving, setSaving] = useState(false);

  const savePage = async () => {
    if (onSave === undefined) return;
    setSaving(true);
    try {
      await onSave(serialize({ page, meta, text: translatedText, audio: translatedAudio }));
    } catch (e) {
      // todo handle better...
      alert(e);
    } finally {
      setSaving(false);
    }
  };

  const textElements = [
    page.title,
    ...page.elements
      .map(<T extends Block>(block: T): Extract<T, { text: TranslateableText }>["text"] | null =>
        // @ts-ignore(arlyon): We know all objects with text are extract-able
        "text" in block ? block.text : null
      )
      .filter(<T,>(t: T | null): t is T => t !== null),
  ];

  const source = textElements.reduce(
    (prev, { translationIndex, source }) => ({ ...prev, [translationIndex]: source }),
    {} as { [key: number]: string }
  );

  const {
    availableTextLangsWithDetails: textLangs,
    availableAudioLangsWithDetails: audioLangs,
  } = useContext(LanguageContext);

  const theme = useTheme();

  return (
    <View style={{ height: Platform.OS === "web" ? "100vh" : "100%" }}>
      <TopNavigation
        title={`Translating "${page.title.source}"`}
        accessoryLeft={(props) => (
          <TopNavigationAction
            {...props}
            icon={(props) => <Icon {...props} name="arrow-left" />}
            onPress={() => navigation.pop()}
          />
        )}
        accessoryRight={(props) => (
          <TopNavigationAction
            {...props}
            icon={(props) => (saving ? <Spinner {...props} /> : <Icon {...props} name="check" />)}
            onPress={savePage}
          />
        )}
      />
      <Navigator
        tabBarOptions={{
          style: {
            backgroundColor: theme["color-primary-700"],
          },
        }}
      >
        <Screen name="PAGE_TRANSLATE_TEXT" options={{ title: "Text" }}>
          {(props) => (
            <PageTranslateTextScreen
              {...props}
              source={source}
              languages={textLangs}
              translations={translatedText}
              onSave={(langID, updates) => {
                // If first time editing lang, create meta
                const langMeta = translatedText[langID]
                  ? translatedText[langID].meta
                  : {
                      id: `${meta.id}-${langID}`,
                      outOfDate: false,
                    };
                const newTranslations: Translations<string> = {
                  ...translatedText,
                  [langID]: {
                    meta: langMeta,
                    translations: updates,
                  },
                };
                setTranslatedText(newTranslations);
              }}
            />
          )}
        </Screen>
        <Screen name="PAGE_TRANSLATE_AUDIO" options={{ title: "Audio" }}>
          {(props) => (
            <PageTranslateAudioScreen
              {...props}
              source={source}
              translations={translatedAudio}
              languages={audioLangs}
              onSave={(langID, updates) => {
                // If first time editing lang, create meta
                const langMeta = translatedText[langID]
                  ? translatedText[langID].meta
                  : {
                      id: `${meta.id}-${langID}`,
                      outOfDate: false,
                    };
                console.log(langMeta);
                const newTranslations: Translations<string> = {
                  ...translatedAudio,
                  [langID]: {
                    meta: langMeta,
                    translations: updates,
                  },
                };
                setTranslatedAudio(newTranslations);
              }}
            />
          )}
        </Screen>
      </Navigator>
    </View>
  );
};

type TranslateProps = {
  source: { [translationId: number]: string };
  translations: Translations<string>;
  languages: LangsWithDetails[];
  onSave: (langID: string, updates: { [key: number]: string }) => void;
};

type PageTranslateTextScreenProps = StackScreenProps<RouteParams, "PAGE_TRANSLATE_TEXT"> &
  TranslateProps;

export const PageTranslateTextScreen: FC<PageTranslateTextScreenProps> = ({
  navigation,
  translations,
  onSave,
  source,
  languages,
}) => (
  <ChooseTranslationVisuals
    translatableLanguages={languages
      .filter((lang) => lang.id !== BUNDLED_TEXT_LANGS.EN)
      .map((lang) => ({ ...lang, state: getTranslationState(source, lang, translations) }))}
    onSelect={(language) => {
      navigation.navigate("PAGE_TRANSLATE_TEXT_MANAGE", {
        langName: language.name,
        source: source,
        translations: translations[language.id],
        onSave: (updates: { [key: number]: string }) => {
          navigation.pop();
          onSave(language.id, updates);
        },
      });
    }}
  />
);

type PageTranslateAudioScreenProps = StackScreenProps<RouteParams, "PAGE_TRANSLATE_AUDIO"> &
  TranslateProps;

export const PageTranslateAudioScreen: FC<PageTranslateAudioScreenProps> = ({
  navigation,
  translations,
  onSave,
  source,
  languages,
}) => (
  <ChooseTranslationVisuals
    translatableLanguages={languages.map((lang) => ({
      ...lang,
      state: getTranslationState(source, lang, translations),
    }))}
    onSelect={(language) => {
      navigation.navigate("PAGE_TRANSLATE_AUDIO_MANAGE", {
        langName: language.name,
        source: source,
        translations: translations[language.id],
        onSave: (updates: { [key: number]: string }) => {
          navigation.pop();
          onSave(language.id, updates);
        },
      });
    }}
  />
);

/**
 * Checks the state of a language for a given set of translation.
 */
const getTranslationState = (
  source: { [translationId: number]: string },
  lang: LangsWithDetails,
  translations: Translations<string>
): TranslationState => {
  const { id } = lang;
  if (!(id in translations)) {
    return { type: "missing" };
  }

  if (translations[id].meta.outOfDate) return { type: "out-of-date" };

  const keys = Object.keys(source);

  const count = keys.filter(
    (translationId) => translations[id].translations[Number(translationId)] !== undefined
  ).length;

  return count === keys.length ? { type: "full" } : { type: "partial", count };
};
