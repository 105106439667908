import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  TopNavigation,
  TopNavigationAction,
  Icon,
  Button,
  Toggle,
  Text,
} from "@ui-kitten/components";
import gql from "graphql-tag";
import React, { FC, useState } from "react";
import { View, Platform, StyleSheet } from "react-native";

import { deserialize, screenStyles } from "@h2c/common";
import { Callout, DeleteConfirmationModal } from "@h2c/common/src/molecules";
import {
  useUpdatePageMutation,
  Page,
  useDeletePageLocationMutation,
  useDeletePageMutation,
} from "@h2c/service/graphql/types";

import { RouteParams } from "../routes";

type PageEditPreambleScreenProps = {
  navigation: StackNavigationProp<RouteParams, "PAGE_EDIT_PREAMBLE">;
  route: RouteProp<RouteParams, "PAGE_EDIT_PREAMBLE">;
};

export const PageEditPreambleScreen: FC<PageEditPreambleScreenProps> = ({ navigation, route }) => {
  const page = route.params.page;
  const [published, setPublished] = useState(page.published);

  const [savePage] = useUpdatePageMutation();
  const savePageUpdates = async (serializedPage: Page, forward = true) => {
    // these are invalid fields on the input object
    delete serializedPage.updatedAt;
    delete serializedPage.createdAt;

    setPublished(serializedPage.published);

    // eslint-disable-next-line no-useless-catch
    try {
      // we can re-run this because it is idempotent
      const { data } = await savePage({
        variables: {
          page: serializedPage,
        },
        optimisticResponse: {
          __typename: "Mutation",
          updatePage: {
            __typename: "Page",
            ...serializedPage,
          },
        },
        update: (proxy) => {
          proxy.writeFragment({
            id: serializedPage.id,
            fragment: gql`
              fragment PublishedPage on Page {
                published
              }
            `,
            data: {
              published: serializedPage.published,
            },
          });
        },
      });

      // this should never trigger, but is here for safety
      if (data === undefined || data.updatePage === null || data.updatePage === undefined) {
        console.error("Saving page returned no id.");
        return;
      }
    } catch (e) {
      // todo(arlyon): can we handle this better?
      setPublished(published);
      throw e;
    }

    if (forward) {
      navigation.navigate("CONFIRMATION", {
        confirmationText: "Your page has been updated.",
        onContinue: () => navigation.popToTop(),
      });
    }
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState<string | null>(null);
  const [deletePageLocation, { loading: dPLocationLoading }] = useDeletePageLocationMutation();
  const [deletePageMutation, { loading: dPLoading }] = useDeletePageMutation();

  // Deletes connection to location then page — if no connection, it may as well not exist
  const deletePage = async () => {
    try {
      await deletePageLocation({ variables: { pageLocationID: page.pageToLocationConnectorID } });
    } catch (e) {
      return setDeleteErrors(`Could not delete page from location — ${e.message}`);
    }

    try {
      await deletePageMutation({ variables: { pageID: page.id } });
    } catch {
      // Do nothing, already deletes from location
    }
    setDeleteErrors(null);
    return navigation.pop(1);
  };

  return (
    <View style={{ height: Platform.OS === "web" ? "100vh" : "100%" }}>
      <TopNavigation
        title="Editing Page"
        accessoryLeft={(props) => (
          <TopNavigationAction
            {...props}
            icon={(props) => <Icon {...props} name="arrow-left" />}
            onPress={() => navigation.pop()}
          />
        )}
      />
      <View style={screenStyles.webCentredColumn}>
        <Text category={"h4"}>{page?.title.source || ""}</Text>
        <Toggle
          style={[styles.button, styles.toggle]}
          status="success"
          checked={published === true}
          onChange={(value) => {
            const pageWithUpdatedPublishedAndLinkToLocationRemoved = { ...page, published: value };
            delete pageWithUpdatedPublishedAndLinkToLocationRemoved.pageToLocationConnectorID;
            savePageUpdates(pageWithUpdatedPublishedAndLinkToLocationRemoved, false);
          }}
        >
          Published
        </Toggle>
        <Button
          style={styles.button}
          onPress={page ? () => navigation.navigate("PAGE_EDIT", { page }) : undefined}
        >
          Edit Content
        </Button>
        <Button
          style={styles.button}
          appearance={"outline"}
          onPress={
            page
              ? () =>
                  navigation.navigate("PAGE_TRANSLATE", {
                    page: deserialize(page),
                    onSave: savePageUpdates,
                  })
              : undefined
          }
        >
          Manage Translations
        </Button>
        <Button style={styles.button} onPress={() => setDeleteModalOpen(true)} status={"danger"}>
          Delete Page
        </Button>
        {deleteErrors && <Callout text={deleteErrors} />}
      </View>

      <DeleteConfirmationModal
        visible={deleteModalOpen}
        hideModal={() => setDeleteModalOpen(false)}
        explainerText={"Deleting pages cannot be undone. Are you sure you wat to continue?"}
        confirmationString={page.title.source}
        onDelete={deletePage}
        deleteLoading={dPLocationLoading || dPLoading}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 16,
  },
  toggle: {
    height: 64,
  },
});
