import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useContext, useState } from "react";

import { PENANG_COORDINATES } from "@h2c/common/src/constants";
import { MapVisuals } from "@h2c/common/src/organisms/MapVisuals";
import { types } from "@h2c/service";
import { IncidentType } from "@h2c/service/graphql/types";
import {
  formatIncidentReports,
  GenericDataItem,
  INCIDENT_TYPE_WITH_DETAILS,
} from "@h2c/service/src/dataItemManipulation";
import { createdAtLessThanNWeeksOld } from "@h2c/service/src/util";

import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";

type CovidReportMapContainerProps = {
  navigation: StackNavigationProp<RouteParams, "INCIDENTS_MAP">;
};

/**
 * Container for map view of Covid reports using MapVisuals
 * @param navigation
 * @constructor
 */
export const IncidentsMapContainer: FC<CovidReportMapContainerProps> = ({ navigation }) => {
  const [{ general }] = useContext(GlobalStateContext);

  const { data, loading, refetch } = types.useListIncidentsByLocationQuery({
    fetchPolicy: "cache-and-network",
    variables: { locationID: general.currentLocation?.id || "" },
  });

  const [selectedReport, setSelectedReport] = useState<GenericDataItem<IncidentType>>();

  const showReport = (r?: GenericDataItem<IncidentType>) => {
    setSelectedReport(r);
  };

  const hideReport = () => {
    setSelectedReport(undefined);
  };

  const refreshData = () => {
    setSelectedReport(undefined);
    refetch();
  };

  const NUMBER_OF_WEEKS_TO_SHOW = 6;
  return (
    <MapVisuals
      onUpdate={(report: GenericDataItem<IncidentType>) =>
        navigation.navigate("INCIDENT_UPDATE", { report: report })
      }
      iconInfo={(report: GenericDataItem<IncidentType>) =>
        INCIDENT_TYPE_WITH_DETAILS.find((t) => t.type === report.type)
      }
      loading={loading}
      refetch={refreshData}
      data={formatIncidentReports(data).filter(createdAtLessThanNWeeksOld(NUMBER_OF_WEEKS_TO_SHOW))}
      defaultCenter={{
        latitude:
          general.currentLocation?.centreCoordinates?.latitude || PENANG_COORDINATES.latitude,
        longitude:
          general.currentLocation?.centreCoordinates?.longitude || PENANG_COORDINATES.longitude,
      }}
      title={"Incidents"}
      show={showReport}
      hide={hideReport}
      selected={selectedReport}
      LocationSelectWithModal={() => <LocationSelectWithModalContainer onChange={refetch} />}
      numberOfWeeksShown={NUMBER_OF_WEEKS_TO_SHOW}
    />
  );
};
