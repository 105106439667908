import React, { useReducer } from "react";
import combineReducers from "react-combine-reducers";

import { CombinedReducerType, GlobalStateContext } from "../state";
import { ADMIN_GENERAL_DEFAULT_STATE, adminGeneralReducer } from "../state/general";

// Export all actions + reducers

const [combinedReducer, initialState] = combineReducers<CombinedReducerType>({
  general: [adminGeneralReducer, ADMIN_GENERAL_DEFAULT_STATE],
});

/* ****** Context Provider ****** */

export const GlobalStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer<CombinedReducerType>(combinedReducer, initialState);
  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>{children}</GlobalStateContext.Provider>
  );
};
