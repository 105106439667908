import { Auth } from "@aws-amplify/auth";
import React, { FC, useState } from "react";

import { useAmplifyAuth } from "@h2c/service";

import { SIGN_IN_SCREENS, SignInVisuals } from "./SignInVisuals";

export const SignInContainer: FC = () => {
  const [currentScreen, setCurrentScreen] = useState(SIGN_IN_SCREENS.SIGN_IN);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [customLoading, setCustomLoading] = useState(false);
  const [userForChangingPassword, setUserForChangingPassword] = useState(false);
  const [secureTextEntry, setSecureTextEntry] = React.useState(true);
  const { signIn } = useAmplifyAuth();

  /**
   * Error checks inputs and forces password reset
   * if required (first time login)
   * @param email
   * @param password
   */
  const adminSignIn = async (email: string, password: string) => {
    setCustomLoading(true);
    // validate email and password
    const errors = [];
    if (!email) {
      errors.push("Please provide your email");
    }

    if (!password) {
      errors.push("Please provide your password");
    }

    if (errors.length == 0) {
      try {
        const user = await signIn(email, password);

        // First time sign in
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setCurrentScreen(SIGN_IN_SCREENS.CHANGE_PASSWORD);
          setValidationErrors([]);
          setUserForChangingPassword(user);
        }
      } catch (e) {
        setValidationErrors([e.message]);
      }
    } else {
      setValidationErrors(errors);
    }
    setCustomLoading(false);
  };

  const adminSetNewPassword = async (newPassword: string) => {
    setCustomLoading(true);
    const errors = [];
    if (!newPassword) {
      errors.push("Please provide your password");
    }

    if (errors.length == 0) {
      try {
        await Auth.completeNewPassword(userForChangingPassword, newPassword, {});
      } catch (e) {
        setValidationErrors([e.message]);
      }
    } else {
      setValidationErrors(errors);
    }
    setCustomLoading(false);
  };

  const sendVerificationCodeToEmail = async (email: string) => {
    setCustomLoading(true);
    try {
      await Auth.forgotPassword(email);
      setValidationErrors([]);
      setCurrentScreen(SIGN_IN_SCREENS.FORGOT_PASSWORD_VERIFICATION_CODE);
    } catch (e) {
      setValidationErrors([e.message]);
    }

    setCustomLoading(false);
  };

  const forgotPasswordResetPassword = async (email: string, code: string, newPassword: string) => {
    setCustomLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      await signIn(email, newPassword);

      setValidationErrors([]);
    } catch (e) {
      setValidationErrors([e.message]);
    }
    setCustomLoading(false);
  };

  return (
    <SignInVisuals
      screen={currentScreen}
      changeScreen={setCurrentScreen}
      signInAction={adminSignIn}
      setNewPassword={adminSetNewPassword}
      forgotPasswordVerifyEmails={sendVerificationCodeToEmail}
      forgotPasswordResetPassword={forgotPasswordResetPassword}
      error={validationErrors.join(", ")}
      loading={customLoading}
      secureTextEntry={secureTextEntry}
      setSecureTextEntry={setSecureTextEntry}
    />
  );
};
