import { StackNavigationProp } from "@react-navigation/stack";
import { Layout } from "@ui-kitten/components";
import React, { FC, useContext } from "react";
import { ListRenderItemInfo, ScrollView } from "react-native";

import { ReportItem } from "@h2c/common/src/molecules/ReportItem";
import { DataList } from "@h2c/common/src/organisms/";
import { screenStyles } from "@h2c/common/src/styles";
import { types } from "@h2c/service";
import { IncidentType } from "@h2c/service/graphql/types";
import {
  GenericDataItem,
  INCIDENT_TYPE_WITH_DETAILS,
  formatIncidentReports,
} from "@h2c/service/src/dataItemManipulation";

import { LocationSelectWithModalContainer } from "../../organisms";
import { RouteParams } from "../../routes";
import { GlobalStateContext } from "../../state";

type CovidReportListScreenProps = {
  navigation: StackNavigationProp<RouteParams, "INCIDENTS_LIST">;
};

/**
 * The list view for incident reports using DataList component
 * @param navigation
 * @constructor
 */
export const IncidentsListScreen: FC<CovidReportListScreenProps> = ({ navigation }) => {
  const [{ general }] = useContext(GlobalStateContext);
  const { data, loading, refetch } = types.useListIncidentsByLocationQuery({
    fetchPolicy: "cache-and-network",
    variables: { locationID: general.currentLocation?.id || "" },
  });

  return (
    <ScrollView>
      <Layout style={[screenStyles.webCentredColumnNarrow, { marginTop: 10 }]}>
        <LocationSelectWithModalContainer onChange={refetch} />
        <DataList
          noDataMessage={"No previous reports"}
          title={"Incidents"}
          renderItem={({ item }: ListRenderItemInfo<GenericDataItem<IncidentType>>) => (
            <ReportItem
              report={item}
              onPress={() => navigation.navigate("INCIDENT_UPDATE", { report: item })}
              reportInfo={INCIDENT_TYPE_WITH_DETAILS.find((t) => t.type === item.type)}
            />
          )}
          data={formatIncidentReports(data)}
          refreshing={loading}
          onRefresh={() => refetch()}
        />
      </Layout>
    </ScrollView>
  );
};
