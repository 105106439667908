import { StackNavigationProp } from "@react-navigation/stack";
import { Layout } from "@ui-kitten/components";
import React, { FC, useEffect, useState } from "react";

import { screenStyles } from "@h2c/common/src";
import { LanguageEditSection } from "@h2c/common/src/organisms";
import { uploadFileToS3 } from "@h2c/common/src/util";
import { types } from "@h2c/service";

import { RouteParams } from "../../routes";

type LanguageAddNewAudioLangScreenProps = {
  navigation: StackNavigationProp<RouteParams, "LANGUAGE_CREATE_AUDIO">;
};

export const LanguageAddNewAudioLangScreen: FC<LanguageAddNewAudioLangScreenProps> = ({
  navigation,
}) => {
  const [flagS3Uri, setFlagS3Uri] = useState("");
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [errors, setError] = useState<string[]>([]);

  const [
    createAudioLang,
    { loading: saveLoading, error: saveErrors },
  ] = types.useCreateAudioLangMutation();
  useEffect(() => {
    if (saveErrors) {
      setError(errors.concat(saveErrors.message));
    }
  }, [saveErrors]);
  const addLanguage = async (name: string) => {
    try {
      // TODO(ssp6): Put these as one function on backend - handled unhappy path better
      await createAudioLang({ variables: { name, flagImageUri: flagS3Uri } });

      navigation.navigate("CONFIRMATION", {
        confirmationText: `The audio language "${name}" has been created!`,
        onContinue: () => navigation.pop(2),
      });
    } catch (e) {
      setError([e]);
    }
  };

  const uploadLanguageImage = async (localUri: string, name: string) => {
    setUploadImageLoading(true);
    try {
      const result = await uploadFileToS3(localUri, `language-flag-${name}`);
      setFlagS3Uri(result);
    } catch (e) {
      setError(e.message);
    }

    setUploadImageLoading(false);
  };

  return (
    <Layout style={screenStyles.webCentredColumn}>
      <LanguageEditSection
        headingText={"Create New Audio Language"}
        flagImageUri={flagS3Uri}
        saveButtonText={"Create Language"}
        saveAction={addLanguage}
        saveLoading={saveLoading}
        uploadLanguageImage={uploadLanguageImage}
        uploadImageLoading={uploadImageLoading}
        error={errors.join(", ") || null}
        setError={(error: string) => setError(errors.concat(error))}
      />
    </Layout>
  );
};
